import {
  RSettings,
  Kitchen,
  Comments,
  WeeklySchedules,
  EcSetting,
  TableData,
  TakeoutSettings,
  UserCancelation,
  EatinSettings,
  LineMiniApp,
  PrinterInfo,
  WaitingPoint,
  TimeFrame,
  PrintDevice,
  WaitingInfo,
} from '@/store/models/db/r-settings';
import {
  categoryType,
  miniAppPaymentMethods,
  paymentStatus,
  salesType,
  userInfoItem,
  waitingImageType,
} from '@/common/appCode';
import * as Utils from '@/common/utils';
import { salesTypeValue, userInfoItemValue } from '@/common/appCodeValue';
import { Table } from './table';
import { Timestamp } from 'firebase/firestore';
import { CategorizedTables } from './db/common-models';
import { AccountSetting } from './account-setting';
import { useUsersStore } from '@/stores/users';
import { range } from 'lodash';

export type SettingsModel = RSettings;

export interface KitchenModel extends Kitchen {
  key: string;
}

/** アプリケーションで使うモデル **/
export class Settings {
  private userStore;

  constructor(public params: SettingsModel) {
    this.userStore = useUsersStore();
  }

  /** ポジション名の登録可能最大文字数 */
  public static MAX_KITCHEN_NAME_LENGTH = 10;

  /** Welcomeメッセージの最大登録可能最大文字数 */
  public static MAX_WELCOME_MESSAGE_LENGTH = 100;

  /** ボタンラベルの最大登録可能最大文字数 */
  public static MAX_BUTTON_LABEL_LENGTH = 10;

  /** 最長予約受付可能日数の最大値 */
  public static MAX_END_DATE_OF_TAKEOUT_BOOKING_NUMBER = 120;

  /** 最長予約受付可能日数の最大値 */
  public static MAX_NUM_OF_REGISTRABLE_CLOSED_ON_DAY = 60;

  /** トップコメントの最大登録可能文字数 */
  public static MAX_TOP_COMMENT_LENGTH = 16;

  /** 事前注文テーブルのカテゴリー（予約語、カテゴリー指定不可） */
  public static PRE_ORDER_TABLE_CATEGORY = 'ウェイティング';

  public static DEFAULT_BUSINESS_SCHEDULE: WeeklySchedules = {
    monday: { closed: true, businessHours: [] },
    tuesday: { closed: false, businessHours: [] },
    wednesday: { closed: false, businessHours: [] },
    thursday: { closed: false, businessHours: [] },
    friday: { closed: false, businessHours: [] },
    saturday: { closed: false, businessHours: [] },
    sunday: { closed: false, businessHours: [] },
    publicHoliday: { closed: false, ignoreWeekDay: false, businessHours: [] },
  };

  public static DEFAULT_USE_LANGUAGE = {
    ja: false,
    en: false,
    zh: false,
    ko: false,
  };

  /** 多言語対応項目の初期値 */
  public static DEFAULT_MULTILINGUAL = {
    ja: '',
    en: '',
    zh: '',
    ko: '',
  };

  /**
   * ポジションの配列を取得
   */
  kitchenArray(): KitchenModel[] {
    return Object.keys(this.kitchen)
      .map((key) => {
        return {
          key: key,
          name: this.kitchen[key].name,
          sort_key: this.kitchen[key].sort_key,
        };
      })
      .sort((k1, k2) => {
        if (k1.sort_key > k2.sort_key) return 1;
        if (k1.sort_key < k2.sort_key) return -1;
        return 0;
      });
  }

  get kitchen(): { [key: string]: Kitchen } {
    return this.params?.kitchen ?? {};
  }

  get preparedTables(): string[] {
    // sortで配列が破壊されるので間にsliceを挟む
    return (
      this.params?.preparedTables?.slice()?.sort((a, b) => {
        const sa = String(a).replace(/(\d+)/g, (m) => m.padStart(5, '0'));
        const sb = String(b).replace(/(\d+)/g, (m) => m.padStart(5, '0'));
        return sa < sb ? -1 : sa > sb ? 1 : 0;
      }) ?? []
    );
  }

  get tableData(): TableData[] {
    return (
      this.params?.tableData ??
      this.preparedTables.map(
        (val, i): TableData => ({
          id: `${i}`,
          tableNo: val,
          tableCategory: '',
          capacity: 99,
          printerGroup: '',
        })
      )
    );
  }

  get eatinSetting(): EatinSettings {
    return this.params?.eatinSetting ?? ({} as EatinSettings);
  }

  get takeoutSetting(): TakeoutSettings {
    return this.params?.takeoutSetting ?? ({} as TakeoutSettings);
  }

  get waitingInfo(): WaitingInfo {
    return this.params?.waitingInfo ?? ({} as WaitingInfo);
  }

  get userCancelation(): UserCancelation {
    return this.takeoutSetting.userCancelation
      ? this.takeoutSetting.userCancelation
      : {
          enable: false,
          limitType: 'day',
          hour: 0,
          day: 0,
          time: '00:00',
        };
  }

  get ecSetting(): EcSetting {
    return this.params?.ecSetting ?? ({} as EcSetting);
  }

  get usePrepay(): boolean {
    return this.takeoutSetting.usePrepay ?? true;
  }

  get useTablePay(): boolean {
    return this.eatinSetting.useTablePay ?? true;
  }

  get useInvoicePayment(): boolean {
    return this.takeoutSetting.paymentMethods?.includes(miniAppPaymentMethods.invoice) ?? false;
  }

  get hideStockEatin(): boolean {
    return this.eatinSetting?.hideStock ?? false;
  }

  get hideStockPicking(): boolean {
    return this.takeoutSetting?.hideStock ?? false;
  }

  get labelOfTakeout(): string {
    return this.params?.labelOfTakeout1 || salesTypeValue.takeout;
  }

  get labelOfTakeout2(): string {
    return this.params?.labelOfTakeout2 || salesTypeValue.takeout2;
  }

  get labelOfDineIn(): string {
    return this.params?.labelOfDineIn || salesTypeValue.dineIn;
  }

  get deliveryFee(): number {
    return this.params?.deliveryFee ?? 0;
  }

  get deliveryFeeLimitToFree(): number {
    return this.params?.deliveryFeeLimitToFree ?? 0;
  }

  get maxReservationDays(): number {
    return this.takeoutSetting.maxReservationDays;
  }

  get weeklySchedules(): WeeklySchedules {
    const result = Object.keys(Settings.DEFAULT_BUSINESS_SCHEDULE).reduce((res, key) => {
      const defaultSchedule = Settings.DEFAULT_BUSINESS_SCHEDULE[key];

      // ここで型アサーションを使用して、keyがWeeklySchedulesのキーであることを明示
      const scheduleKey = key as keyof WeeklySchedules;

      const weeklySchedules = {
        ...res,
        [scheduleKey]: {
          closed: this.params?.weeklySchedule?.[scheduleKey]?.closed ?? defaultSchedule.closed,
          businessHours: this.params?.weeklySchedule?.[scheduleKey]?.businessHours ?? defaultSchedule.businessHours,
        },
      };

      return weeklySchedules;
    }, {} as WeeklySchedules);

    result.publicHoliday.ignoreWeekDay = this.params?.weeklySchedule?.publicHoliday?.ignoreWeekDay ?? false;

    return result;
  }

  get closedOn(): string[] {
    return (
      this.params?.closedOn
        ?.filter((val) => Utils.isDateSameOrAfter(val, Utils.dateFormatByHyphen(new Date())))
        .sort() ?? []
    );
  }

  get voiceCallMessage(): string {
    return this.params?.waitingInfo?.voiceCallMessage ?? '';
  }

  get lineMiniApp(): LineMiniApp | null {
    return this.params.lineMiniApp ?? null;
  }

  get isEatinMiniAppUsed(): boolean {
    return Boolean(this.lineMiniApp?.anytime);
  }

  get isTakeoutMiniAppUsed(): boolean {
    return Boolean(this.lineMiniApp?.anywhere);
  }

  get isMembershipMiniAppUsed(): boolean {
    return Boolean(this.lineMiniApp?.membership);
  }

  get isWaitingMiniAppUsed(): boolean {
    return Boolean(this.lineMiniApp?.waiting);
  }

  get isEcMiniAppUsed(): boolean {
    return Boolean(this.lineMiniApp?.ec);
  }

  get isTakeoutOpen(): boolean {
    return (
      this.params?.takeoutOpen || this.params?.takeout2Open || this.params?.curbsideOpen || this.params?.dineInOpen
    );
  }

  get isDeliveryOpen(): boolean {
    return this.params?.deliveryOpen ?? false;
  }

  get printer(): PrinterInfo | null {
    return this.params?.printer ?? null;
  }

  get printDevice(): PrintDevice | null {
    return this.params?.printDevice ?? null;
  }

  /** 受注伝票用プリンター契約店舗かどうか */
  get isUseKitchenPrinterAvailable(): boolean {
    return this.printer?.orderTickets ?? false;
  }
  /** 受注伝票用プリンター契約店舗かどうか */
  get isUseTableOrderPrinterAvailable(): boolean {
    return this.printer?.tableOrderQR ?? false;
  }
  /** 順番待ち用プリンター契約店舗かどうか */
  get isUseWaitingPrinterAvailable(): boolean {
    return this.printer?.waitingQR ?? false;
  }
  /** 注文取消伝票を出力するかどうか */
  get isUseOrderCancelTicket(): boolean {
    return this.printer?.orderCancel ?? false;
  }

  // /** tableOrderQRのプリンター設定 */
  // get tableOrderPrinterSetting(): Printer | null {
  //   return this.printer?.setting?.tableOrderQR ?? null;
  // }
  // /** waitingQRのプリンター設定 */
  // get waitingPrinterSetting(): Printer | null {
  //   return this.printer?.setting?.waitingQR ?? null;
  // }

  /** webhook連携状況 */
  get lineWebhook(): boolean {
    return this.params.lineWebhook ?? false;
  }

  /** MessagingAPI連携済みかどうか */
  get isMessagingApiAttached(): boolean {
    return Boolean(this.params.lineChannelId) && Boolean(this.params.lineChannelSecret);
  }

  /** ModuleChannel連携済みかどうか */
  get isModuleChannelAttached(): boolean {
    return Boolean(this.params?.moduleChannel);
  }

  get isLineAttached(): boolean {
    return this.isMessagingApiAttached || this.isModuleChannelAttached;
  }

  /** 店内モバイルオーダーでユーザがお会計操作可能かどうか */
  get userPaymentEatin(): boolean {
    return this.params.eatinSetting?.userPayment ? true : false;
  }

  /** 受取場所 */
  get pickUpPlaces(): string[] {
    return this.takeoutSetting.pickUpPlaces ?? [];
  }

  get useAsap(): boolean {
    return this.takeoutSetting.useAsap ?? false;
  }

  /** 受取日のみ（受取時間を使用しない） */
  get useOnlyPickingDate(): boolean {
    return this.takeoutSetting.useOnlyPickingDate ?? false;
  }

  /** 受取日時の分を使用しない */
  get useOnlyPickingHour(): boolean {
    return this.takeoutSetting.useOnlyPickingHour ?? false;
  }

  get closedDayDisplay(): string[] {
    return [
      { key: 'monday', value: '月曜日' },
      { key: 'tuesday', value: '火曜日' },
      { key: 'wednesday', value: '水曜日' },
      { key: 'thursday', value: '木曜日' },
      { key: 'friday', value: '金曜日' },
      { key: 'saturday', value: '土曜日' },
      { key: 'sunday', value: '日曜日' },
      { key: 'publicHoliday', value: '祝日' },
    ]
      .filter((val) => this.weeklySchedules[val.key]?.closed)
      .map((val) => val.value);
  }

  /** 順番待ち受付可能か */
  get waitingAvailable(): boolean {
    return Boolean(this.waitingInfo.startWaiting && !this.waitingInfo.suspendedWaiting);
  }

  /** 待ち子ちゃんの衣装 */
  get machikoImage(): string {
    if (this.waitingInfo.imageType === waitingImageType.shop) {
      return `machiko_welcome_shop.png`;
    }
    if (this.waitingInfo.imageType === waitingImageType.company) {
      return `machiko_welcome_company.png`;
    }
    if (this.waitingInfo.imageType === waitingImageType.apron) {
      return `machiko_apron.png`;
    }

    return `machiko_welcome_shop.png`;
  }

  /** 順番待ちで同意画面を表示するかどうか */
  get isShowAgreement(): boolean {
    return this.waitingInfo.waitingAgreement ? true : false;
  }

  /** 順番待ちで人数を確認するかどうか */
  get isUsingPeople(): boolean {
    return this.waitingInfo.usingPeople ?? false;
  }

  get isUsingKids(): boolean {
    return this.isUsingPeople && Boolean(this.waitingInfo.usingKids);
  }

  /** 順番待ちで希望条件1を確認するかどうか */
  get isUsingRequest1(): boolean {
    return this.waitingInfo.request1Content && this.waitingInfo.request1Content.length > 0 ? true : false;
  }

  /** 順番待ちで希望条件2を確認するかどうか */
  get isUsingRequest2(): boolean {
    return this.waitingInfo.request2Content && this.waitingInfo.request2Content.length > 0 ? true : false;
  }

  /** 順番待ちで音声呼び出しをするか */
  get isVoiceCall(): boolean {
    return this.waitingInfo.voiceCall &&
      this.waitingInfo.voiceCallMessage &&
      this.waitingInfo.voiceCallMessage.length > 0
      ? true
      : false;
  }

  /** 順番待ちポイント */
  get waitingPoint(): WaitingPoint {
    return (
      this.waitingInfo.waitingPoint ?? {
        enable: false,
        threshold: 0,
        interval: 0,
        point: 0,
        maxNumber: 0,
      }
    );
  }

  /** 多言語を使用するかどうか */
  get isUseMultilingual(): boolean {
    return this.params.useMultilingual ?? false;
  }

  /** 多言語使用時に日本語を使用するかどうか */
  get isUseJapanese(): boolean {
    return this.params.useLanguage?.ja ?? false;
  }

  /** 多言語使用時に英語を使用するかどうか */
  get isUseEnglish(): boolean {
    return this.params.useLanguage?.en ?? false;
  }

  /** 多言語使用時に中国語を使用するかどうか */
  get isUseChinese(): boolean {
    return this.params.useLanguage?.zh ?? false;
  }

  /** 多言語使用時に韓国語を使用するかどうか */
  get isUseKorean(): boolean {
    return this.params.useLanguage?.ko ?? false;
  }

  get businessHours(): TimeFrame[] {
    return [
      { from: this.params.takeoutOpenTime, to: this.params.takeoutCloseTime },
      ...(this.params.takeoutOpenTime2 && this.params.takeoutCloseTime2
        ? [{ from: this.params.takeoutOpenTime2, to: this.params.takeoutCloseTime2 }]
        : []),
    ];
  }

  /**
   * 曜日の受取時間帯を返す
   */
  get existedWeeklySchedules(): { day: string; businessHours: TimeFrame[] }[] {
    const weeklySchedule = this.weeklySchedules;

    if (!weeklySchedule) {
      return [];
    }

    return [
      { day: '月曜日', businessHours: weeklySchedule.monday?.businessHours ?? [] },
      { day: '火曜日', businessHours: weeklySchedule.tuesday?.businessHours ?? [] },
      { day: '水曜日', businessHours: weeklySchedule.wednesday?.businessHours ?? [] },
      { day: '木曜日', businessHours: weeklySchedule.thursday?.businessHours ?? [] },
      { day: '金曜日', businessHours: weeklySchedule.friday?.businessHours ?? [] },
      { day: '土曜日', businessHours: weeklySchedule.saturday?.businessHours ?? [] },
      { day: '日曜日', businessHours: weeklySchedule.sunday?.businessHours ?? [] },
      { day: '祝日', businessHours: weeklySchedule.publicHoliday?.businessHours ?? [] },
    ].filter((val) => val && val.businessHours.length > 0);
  }

  get useTimeLimit(): boolean {
    return this.eatinSetting.useTimeLimit?.enable ?? false;
  }

  get limitMinutes(): number {
    return this.eatinSetting.useTimeLimit?.limitMinutes ?? 0;
  }

  get pickupTimeSelectInterval(): number {
    return this.takeoutSetting.pickupTimeSelectInterval ?? 1;
  }

  get pickupTimeMinutesList(): number[] {
    return range(0, 60, this.pickupTimeSelectInterval);
  }

  public isTakeoutOrdering(now = new Date()): boolean {
    // 注文不可時間帯の設定がない場合は常にtrue
    if (!this.params?.takeoutNotOrderingTimes?.length) {
      return true;
    }

    // 注文不可時間帯の設定がある場合
    return this.params.takeoutNotOrderingTimes.every((val) => {
      const fromTime = Utils.createDateWithTime(val.from, now);
      const toTime = Utils.createDateWithTime(val.to, now);

      // 現在の時刻が from より前か、to より後かを判定（注文不可時間帯に含まれていない）
      return Utils.isBefore(now, new Date(fromTime)) || Utils.isDateAfter(now, new Date(toTime));
    });
  }

  public minAndMaxPickingTime(now: Date, receiveType: salesType): { minDateTime: Date; maxDateTime: Date } {
    const minDateTime = Utils.addMinutes(now, this.getEarliestTime(receiveType));
    const maxDateTime = Utils.addDays(now, this.maxReservationDays);

    return { minDateTime, maxDateTime };
  }

  /**
   * デリバリー受付可能な金額かどうか
   *
   * @param pay
   */
  public isAmountOfDeliveryAvailable(pay: number, deliveryMinimumFrom: number): boolean {
    return this.params.deliveryOpen && pay >= deliveryMinimumFrom;
  }

  /**
   * 配達費を取得
   *
   * @param type
   */
  public getDeliveryFee(type: salesType): number {
    return type === salesType.delivery ? this.deliveryFee : 0;
  }

  /**
   * 配達下限額を取得
   * @param postalCodeMinimumFrom
   */
  public getDeliveryMinimumFrom(postalCodeMinimumFrom?: number): number {
    return postalCodeMinimumFrom ?? this.params.deliveryMinimumFrom ?? 0;
  }

  /**
   * 受け取り方法を取得する
   *
   * @param type
   */
  public getSalesTypeValue(type: salesType): string {
    return (
      {
        [salesType.eatin]: salesTypeValue.eatin,
        [salesType.dineIn]: this.labelOfDineIn,
        [salesType.takeout]: this.labelOfTakeout,
        [salesType.takeout2]: this.labelOfTakeout2,
        [salesType.delivery]: salesTypeValue.delivery,
        [salesType.curbside]: salesTypeValue.curbside,
      }[type] ?? '不明'
    );
  }
  /**
   * 受け取り方法別の最短受取可能時間を取得
   *
   * @param type
   */
  public getEarliestTime(type: salesType): number {
    return (
      {
        [salesType.dineIn]: this.params.earliestTimeTakeout,
        [salesType.takeout]: this.params.earliestTimeTakeout,
        [salesType.takeout2]: this.params.earliestTimeTakeout,
        [salesType.curbside]: this.params.earliestTimeTakeout,
        [salesType.delivery]: this.params.earliestTimeDelivery,
      }[type] ?? 0
    );
  }

  /**
   * コメントを取得
   *
   * @param key
   * @returns
   */
  public getComment(key: keyof Comments): string {
    return this.params.comments?.[key] ?? '';
  }

  /**
   * 全てのイートインテーブルを取得
   * カテゴリー別にグルーピング
   *
   * @param tables アクティベート済みテーブル
   * @returns
   */
  public getEatinTableData(tables: Table[]): CategorizedTables[] {
    // TODO: 彩家、湊庵はtableDataがないので、暫定対応で生データ（params）を使う。変更するときは仕様が変わることを伝える
    const tableData = this.params.tableData;
    if (!tableData || Object.keys(tableData).length === 0) {
      return [{ tableCategory: '', tables: tables }];
    }

    const addedTableNos = tableData.map((val) => val.tableNo);
    const preOrderTables = tables.filter((val) => val.isPreOrder);
    const extraTables = tables.filter((val) => !val.isPreOrder && !addedTableNos.includes(val.tableNo));

    const dataSet = Utils.groupBy(
      [
        // 登録済みテーブル
        ...tableData,
        // 登録外のテーブル
        ...extraTables.map(
          (val): TableData => ({
            id: val.id,
            capacity: val.params.numberOfGuests,
            tableNo: val.tableNo,
            tableCategory: '登録外',
            printerGroup: '',
          })
        ),
        // 事前注文テーブル
        ...preOrderTables.map(
          (val): TableData => ({
            id: val.id,
            capacity: val.params.numberOfGuests,
            tableNo: val.tableNo,
            tableCategory: Settings.PRE_ORDER_TABLE_CATEGORY, // TODO:定数化
            printerGroup: '',
          })
        ),
      ],
      'tableCategory'
    );
    return Object.keys(dataSet).map((key) => ({
      tableCategory: key || 'カテゴリーなし',
      tables: [
        ...dataSet[key].map(
          (val) =>
            // アクティベート済みテーブル
            tables.find(
              (v) =>
                v.tableNo === val.tableNo &&
                // 事前注文テーブルの場合は事前注文フラグが一致するものを返す
                (val.tableCategory === Settings.PRE_ORDER_TABLE_CATEGORY ? v.isPreOrder : !v.isPreOrder)
            ) ??
            // アクティベートされていないテーブル
            new Table({
              tableNo: val.tableNo,
              tableDataId: val.id,
              id: '',
              salesType: salesType.eatin,
              salesTypeValue: '',
              numberOfGuests: val.capacity,
              discount: 0,
              fee: 0,
              taxSetting: AccountSetting.DEFAULT_TAX_SETTING,
              note: '',
              order: [],
              orderHistory: [],
              orderTickets: {},
              lineUsers: [],
              lineUserIds: [],
              paymentStatus: paymentStatus.beforePayment,
              availableCategoryTypes: [],
              entrytime: Timestamp.now(),
              receivingAt: Timestamp.now(),
            })
        ),
      ],
    }));
  }

  /**
   * 休業日かどうか
   *
   * その他休業日が最優先。
   * その他休業日が設定されていない場合、祝日なら「祝日設定を優先する」設定によって変化（実装コード参照）、祝日以外は曜日ごとの休業日を確認。
   * いずれにも該当しない場合は営業日。
   *
   * @param date
   */
  public isOnCloseDay(date: Date): boolean {
    // その他休業日かどうか
    const isOtherClosedDay = this.closedOn.includes(Utils.dateFormatByHyphen(date));

    if (isOtherClosedDay) {
      return true;
    }

    const weeklySchedule = this.weeklySchedules;
    const publicHolidays = this.userStore.getPublicHoliday;

    // 指定日が祝日かどうかをチェック
    const isPublicHoliday = publicHolidays?.[Utils.dateFormatByHyphen(date)] !== undefined;

    // 祝日の場合、ignoreWeekDayの設定をチェック
    const ignoreWeekDay = weeklySchedule.publicHoliday?.ignoreWeekDay ?? false;

    // 曜日に基づく定休日の設定
    const dayOfWeekClosed = {
      0: weeklySchedule.sunday.closed,
      1: weeklySchedule.monday.closed,
      2: weeklySchedule.tuesday.closed,
      3: weeklySchedule.wednesday.closed,
      4: weeklySchedule.thursday.closed,
      5: weeklySchedule.friday.closed,
      6: weeklySchedule.saturday.closed,
    }[date.getDay()];

    // 祝日の場合
    if (isPublicHoliday) {
      // ignoreWeekDay（祝日設定を優先する）
      return ignoreWeekDay
        ? // 祝日設定を優先の場合
          // 祝日の設定のみ評価
          weeklySchedule.publicHoliday?.closed
        : // 祝日設定を優先しない場合
          // 祝日が定休日、または曜日ごとの定休日いずれかが定休日かどうか
          weeklySchedule.publicHoliday?.closed === true || Boolean(dayOfWeekClosed);
    } else {
      // 曜日ごとの定休日設定
      return Boolean(dayOfWeekClosed);
    }
  }
  /**
   * 日付ごとの受取時間帯を返す
   *
   * @param dayOfWeek
   * @returns
   */
  public getTakeoutableTimes(date: string): TimeFrame[] {
    // 日付の曜日または祝日を取得
    const dayOfWeek =
      this.userStore.getPublicHoliday?.[Utils.dateFormatByHyphen(date)] !== undefined
        ? 'publicHoliday'
        : new Date(date).toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();

    return this.params.weeklySchedule?.[dayOfWeek] &&
      !this.params.weeklySchedule[dayOfWeek].closed &&
      this.params.weeklySchedule[dayOfWeek].businessHours?.length > 0 &&
      !this.closedOn.includes(Utils.dateFormatByHyphen(date))
      ? this.params.weeklySchedule?.[dayOfWeek]?.businessHours
      : this.businessHours;
  }

  /**
   * 営業時間外かどうか
   *
   * @param date
   */
  public isOutOfBusinessTime(date: Date, dateTimeSelectable: boolean): boolean {
    // 時間指定不可の場合やtargetDateがInvalid dateの場合はfalse
    if (!dateTimeSelectable || isNaN(date.getTime())) {
      return false;
    }
    // 休業日
    if (this.isOnCloseDay(date)) {
      return true;
    }

    return !this.getTakeoutableTimes(Utils.dateFormatByHyphen(date)).some((val) => {
      const businessTime = Utils.getOpenCloseTime(Utils.getBusinessDate(date, val.from), val.from, val.to);
      return businessTime.openTime <= date && date <= businessTime.closeTime;
    });
  }

  /**
   * 決済手数料を取得
   *
   * @param paymentMethod
   * @returns
   */
  public getPaymentFee(paymentMethod: miniAppPaymentMethods): number {
    return (
      {
        [miniAppPaymentMethods.csp]: this.ecSetting.cspFee,
        [miniAppPaymentMethods.cod]: this.ecSetting.codFee,
      }[paymentMethod] ?? 0
    );
  }

  /**
   * 質問する項目を取得
   */
  public getItemsToAsk(type: salesType): userInfoItem[] | null {
    return this.params.askingUserInfo?.[type] ?? null;
  }

  /**
   * 質問する項目名称を取得
   */
  public getLabelToAsk(type: userInfoItem): string {
    const other1 = this.takeoutSetting.otherQuestion1?.name;
    const other2 = this.takeoutSetting.otherQuestion2?.name;
    const other3 = this.takeoutSetting.otherQuestion3?.name;

    if (type === userInfoItem.other1 && other1) {
      return other1;
    }

    if (type === userInfoItem.other2 && other2) {
      return other2;
    }

    if (type === userInfoItem.other3 && other3) {
      return other3;
    }

    return userInfoItemValue[type] ?? '不明';
  }

  /**
   * LINEミニアプリを登録している商品カテゴリー
   */
  public isMiniAppUsedCategory(type: categoryType): boolean {
    return (
      (type === categoryType.eatin && this.isEatinMiniAppUsed) ||
      (type === categoryType.takeout && this.isTakeoutMiniAppUsed) ||
      type === categoryType.ec
    );
  }

  // getPrinterDeviceId(type: keyof PrinterSetting): string {
  //   return this.printer?.setting?.[type]?.deviceId || 'local_printer'; // local_printerは初期値
  // }
}
