import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';
import { db } from '@/firebase';
import { Table } from '@/store/models/table';
import {
  DataConflict,
  DocumentationDoesNotExist,
  NecessaryDataDoesNotExist,
  StatusError,
  StoreError,
} from '@/common/error';
import {
  AvailableCategoryType,
  Order,
  OrderData,
  OrderHistory,
  OrderTicket,
  RTables,
} from '@/store/models/db/r-tables';
import { api } from '@/firebase/api';
import { orderType, salesType, staffCallMenu } from '@/common/appCode';
import { docs, subCollections } from '@/firebase/dao';
import {
  Unsubscribe,
  arrayUnion,
  deleteField,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
  getCountFromServer,
  runTransaction,
} from 'firebase/firestore';
import { RCategoryTypes } from '@/store/models/db/r-category-types';
import { RGuests } from '@/store/models/db/r-guests';
import { AddTransactionReq, AddTransactionRes } from '@/firebase/dto/add-transaction';
import { GetWaiterOrderDataReq, GetWaiterOrderDataRes } from '@/firebase/dto/smaregi';
import { MergeTableReq, MergeTableRes } from '@/firebase/dto/merge-table';
import { UpdateTableReq, UpdateTableRes } from '@/firebase/dto/update-table';
import { FinishTransactionReq, FinishTransactionRes } from '@/firebase/dto/finish-transaction';
import { OrderInKitchen, OrderTickets } from '@/store/models/order-tickets';
import { RSettings, TableData } from '@/store/models/db/r-settings';
import { ROrderTicketsPrintJob, printStatus } from '@/store/models/db/r-order-tickets-print-job';
import { UpdateOrderTicketsPrintJobReq, UpdateOrderTicketsPrintJobRes } from '@/firebase/dto';
import { RGuestTicketsPrintJob } from '@/store/models/db/r-guest-tickets-print-job';
import {
  UpdateTableTicketsPrintJobDtoReq,
  UpdateTableTicketsPrintJobDtoRes,
} from '@/firebase/dto/update-table-tickets-print-job';
import {
  CallingStaffReq,
  CallingStaffRes,
  CurbsideArrivalReq,
  CurbsideArrivalRes,
} from '@/firebase/dto/store-notification';
import { orderQRCodePrint } from '@/common/utils';
import { EnvConfig } from '@/common/env-config';
import { useUsersStore } from '@/stores/users';
import { GetOrderTicketsPrintJobReq, GetOrderTicketsPrintJobRes } from '@/firebase/dto/get-order-tickets-print-job';
import { OrderAvailabilityCheckReq, OrderAvailabilityCheckRes } from '@/firebase/dto/transaction';
import { staffCallMenuValue } from '@/common/appCodeValue';

export const useTablesStore = defineStore('tables', () => {
  const userStore = useUsersStore();

  // stateはref変数
  const tables = ref([]) as unknown as Ref<Table[]>; // イートイン用
  const targetTables = ref([]) as unknown as Ref<Table[]>; // イートインタブレット注文用
  const userTable = ref(null) as Ref<Table | null>; // イートインミニアプリ用
  const printJobs = ref([]) as Ref<ROrderTicketsPrintJob[]>; // イートインミニアプリ用
  const ordersInKitchen = ref([]) as Ref<OrderInKitchen[]>;
  const dbUnsubscriber = ref() as Ref<Unsubscribe | undefined>;
  const dbUnsubscriber3 = ref() as Ref<Unsubscribe | undefined>;
  const dbUnsubscriber4 = ref() as Ref<Unsubscribe | undefined>;

  // gettterはcomputed
  const getTables = computed(
    () => tables.value.reduce((obj, val) => ({ ...obj, [val.id]: val }), {}) as { [key: string]: Table }
  );
  const getTargetTables = computed((): Table[] => targetTables.value);
  const getUserTable = computed((): Table | null => userTable.value);
  const getTablesArray = computed(() => {
    const tables = getTables.value;
    return Object.keys(tables)
      .map((key) => tables[key])
      .sort((m1, m2) => {
        if (m1.params.tableNo > m2.params.tableNo) return 1;
        if (m1.params.tableNo < m2.params.tableNo) return -1;
        return 0;
      });
  });
  const getKitchens = computed(() =>
    ordersInKitchen.value.map((val): OrderInKitchen => {
      const data = ordersInKitchen.value.find((v) => v.params.kitchenNo === val.params.kitchenNo);
      return new OrderInKitchen({
        ...val.params,
        orders: data?.params.orders ?? [],
      });
    })
  );
  const getPrintJobs = computed((): ROrderTicketsPrintJob[] => printJobs.value);

  // actionはmutationと統合して、関数
  const startTablesSubscribe = async (obj: { contractId: string }) => {
    console.log('startSubscribe r_tables');
    // 店舗情報を取得
    const settingsDoc = await getDoc(docs.settingDoc(obj.contractId, userStore.getStoreId));

    if (!settingsDoc.exists()) {
      throw new DocumentationDoesNotExist();
    }

    const settings = settingsDoc.data() as RSettings;

    const tableIdName: keyof RTables = 'id';

    const q = query(
      subCollections.transactionListCollection(obj.contractId, userStore.getStoreId),
      where(tableIdName, '!=', null) // idフィールドが存在するドキュメントを選択
    );

    const unsubscriber = onSnapshot(q, (querySnapshot) => {
      const res: Table[] = [];

      querySnapshot.forEach((doc) => res.push(new Table(doc.data() as RTables)));

      // Tableをセット
      tables.value = res;

      const orders: OrderTickets[] = res.flatMap((val) => val.orderTickets);

      // ポジションごとにセット
      const kitchens: OrderInKitchen[] = Object.keys(settings.kitchen).map(
        (key) =>
          new OrderInKitchen({
            kitchenNo: key,
            sort_key: settings.kitchen[key].sort_key,
            name: settings.kitchen[key].name,
            orders:
              orders
                ?.filter((order) => order.positionIds.includes(key))
                .sort((o1, o2) => {
                  if (o1.orderedTimeDate < o2.orderedTimeDate) return -1;
                  if (o1.orderedTimeDate > o2.orderedTimeDate) return 1;
                  return 0;
                }) ?? [],
          })
      );

      // 受注伝票をセット
      ordersInKitchen.value = kitchens;
    });

    dbUnsubscriber.value = unsubscriber;
  };

  const startUserTablesSubscribe = (obj: { contractId: string; tableId: string }) => {
    console.log('startUserTablesSubscribe r_tables');

    const unsubscriber = onSnapshot(
      docs.transactionListDoc(obj.contractId, userStore.getStoreId, obj.tableId),
      (doc) => {
        userTable.value = doc.exists() ? new Table(doc.data() as RTables) : null;
      }
    );
    dbUnsubscriber3.value = unsubscriber;
  };

  const startTablesWithTableDataIdSubscribe = (obj: { contractId: string; tableDataId: string }) => {
    console.log('startTablesWithTableDataIdSubscribe r_tables');

    const tableDataIdName: keyof RTables = 'tableDataId';

    const q = query(
      subCollections.transactionListCollection(obj.contractId, userStore.getStoreId),
      where(tableDataIdName, '==', obj.tableDataId) // idフィールドが存在するドキュメントを選択
    );

    const unsubscriber = onSnapshot(q, (querySnapshot) => {
      const res: Table[] = [];

      querySnapshot.forEach((doc) => res.push(new Table(doc.data() as RTables)));

      // Tableをセット
      targetTables.value = res;
    });
    dbUnsubscriber4.value = unsubscriber;
  };

  /**
   * rTableの監視を停止
   *
   * @param state
   */
  const stopTablesSubscribe = () => {
    console.log('stopSubscribe r_tables');
    if (dbUnsubscriber.value) {
      dbUnsubscriber.value();
    }
  };

  /**
   * rTableの監視を停止
   *
   * @param state
   */
  const stopTablesSubscribe3 = () => {
    console.log('stopSubscribe r_tables');
    if (dbUnsubscriber3.value) {
      dbUnsubscriber3.value();
    }
  };

  /**
   * メニューカテゴリの更新
   *
   * @param obj
   */
  const setUserTable = (obj: { table: RTables | null }) => {
    userTable.value = obj.table ? new Table(obj.table) : null;
  };

  /**
   * テーブルの取得
   *
   * @param obj
   */
  const fetchUserTransactions = async (obj: {
    contractId: string;
    lineUserId: string;
    liffAccessToken?: string;
  }): Promise<void> => {
    try {
      const res2: Table[] = [];

      const lineUserIdsName: keyof RTables = 'lineUserIds';

      const q = query(
        subCollections.transactionListCollection(obj.contractId, userStore.getStoreId),
        where(lineUserIdsName, 'array-contains', obj.lineUserId)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => res2.push(new Table(doc.data() as RTables)));

      tables.value = res2;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * テーブルIDからテーブルの取得
   *
   * @param obj
   */
  const fetchUserTransactionsByTableId = async (obj: { contractId: string; tableIds: string[] }): Promise<void> => {
    try {
      const res2: Table[] = [];

      const tableIdsName: keyof RTables = 'id';

      if (obj.tableIds.length > 0) {
        const q = query(
          subCollections.transactionListCollection(obj.contractId, userStore.getStoreId),
          where(tableIdsName, 'in', obj.tableIds)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => res2.push(new Table(doc.data() as RTables)));
      }

      tables.value = res2;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * ウェイターテーブルからtransactionを作成
   *
   * @param obj
   */
  const fetchWaiterTable = async (obj: { contractId: string; tableId: string; liffAccessToken?: string }) => {
    const table = userTable.value;

    if (!table) {
      return;
    }

    try {
      // ウェイターAPI呼び出し
      const res = await api.smaregi<GetWaiterOrderDataReq, GetWaiterOrderDataRes>({
        process: 'get_waiter_order_data',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        tableId: obj.tableId,
        liffAccessToken: obj.liffAccessToken,
      });

      userTable.value = new Table({ ...table.params, order: res.order });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 指定された期間の注文数を返す
   * @param obj
   * @returns
   */
  const getOrderReserveCount = async (obj: { contractId: string; start: Date; end: Date }): Promise<number> => {
    try {
      const receivingAtName: keyof RTables = 'receivingAt';

      const q = query(
        subCollections.transactionListCollection(obj.contractId, userStore.getStoreId),
        where(receivingAtName, '>=', Timestamp.fromDate(obj.start)),
        where(receivingAtName, '<', Timestamp.fromDate(obj.end))
      );
      const querySnapshot = await getCountFromServer(q);

      return querySnapshot.data().count;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  const isOrderAvailable = async (obj: {
    contractId: string;
    targetDate: string;
    liffAccessToken: string;
  }): Promise<boolean> => {
    try {
      const res = await api.transaction<OrderAvailabilityCheckReq, OrderAvailabilityCheckRes>({
        process: 'order_availability_check',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        targetDate: obj.targetDate,
        liffAccessToken: obj.liffAccessToken,
      });

      return res.available;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * テーブルの追加
   *
   * @param obj
   */
  const addTable = async (obj: {
    contractId: string;
    tableNo: string;
    tableDataId: string;
    numberOfGuests: number;
    trainingMode?: boolean;
    preOrder?: boolean;
    preOrderWaitingId?: string;
  }): Promise<{
    contractId: string;
    tableNo: string;
    tableId: string;
  }> => {
    try {
      // テーブル追加処理
      const result: AddTransactionRes = await api.transaction<AddTransactionReq, AddTransactionRes>({
        process: 'add',
        params: {
          type: 'eatin',
          contractId: obj.contractId,
          storeId: userStore.getStoreId,
          tableDataId: obj.tableDataId,
          tableNo: obj.tableNo,
          numberOfGuests: obj.numberOfGuests,
          trainingMode: obj.trainingMode ?? false,
          preOrder: obj.preOrder ?? false,
          preOrderWaitingId: obj.preOrderWaitingId ?? '',
        },
      });

      const settings = userStore.getSettings;

      // 印刷設定が有効の場合、テーブルオーダーQRコードを印刷
      if (settings.printer?.tableOrderQR && settings.printDevice?.tableOrderQR) {
        await printQRCodePrint({
          contractId: result.contractId,
          tableId: result.tableId,
          tableNo: result.tableNo,
        });
      }

      return {
        contractId: result.contractId,
        tableNo: result.tableNo,
        tableId: result.tableId,
      };
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * QRコードチケットを印刷
   *
   * @param obj
   */
  const printQRCodePrint = async (obj: { contractId: string; tableId: string; tableNo: string }) => {
    try {
      const settings = userStore.getSettings;

      // 印刷設定が有効の場合、テーブルオーダーQRコードを印刷
      if (settings.printer?.tableOrderQR && settings.printDevice?.tableOrderQR) {
        const serialNumber = settings.printDevice.tableOrderQR;
        const browserOrder = settings.params.printer.browserOrderQR ?? false;
        const lineMiniApp = settings.lineMiniApp?.anytime;

        await orderQRCodePrint({
          serialNumber: serialNumber,
          storeName: settings.params.name,
          tableId: obj.tableId,
          tableNo: obj.tableNo,
          url: browserOrder
            ? `https://${EnvConfig.app.APP_HOST}//userOrder?contractId=${obj.contractId}&storeId=${userStore.getStoreId}&tableId=${obj.tableId}`
            : `https://miniapp.line.me/${lineMiniApp?.liffId}?tableId=${obj.tableId}`,
        });
      }
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * テーブルの追加
   *
   * @param obj
   */
  const addTakeoutOrder = async (obj: {
    contractId: string;
    salesType: salesType;
    salesTypeValue: string;
    takeoutNote: string;
    name: string;
    tel: string;
    postalCode: string;
    receivingAtStr: string;
    pickUpPlace: string;
    pickingNote: string;
  }): Promise<{
    contractId: string;
    tableNo: string;
    tableId: string;
  }> => {
    try {
      // テーブル追加処理
      const result: AddTransactionRes = await api.transaction<AddTransactionReq, AddTransactionRes>({
        process: 'add',
        params: {
          type: 'picking',
          contractId: obj.contractId,
          storeId: userStore.getStoreId,
          salesType: obj.salesType,
          salesTypeValue: obj.salesTypeValue,
          takeoutNote: obj.takeoutNote,
          name: obj.name,
          tel: obj.tel,
          postalCode: obj.postalCode,
          receivingAtStr: obj.receivingAtStr,
          pickUpPlace: obj.pickUpPlace,
          pickingNote: obj.pickingNote,
        },
      });

      return {
        contractId: result.contractId,
        tableNo: result.tableNo,
        tableId: result.tableId,
      };
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 結合テーブルの追加
   *
   * @param obj
   */
  const addAttachedTables = async (obj: {
    contractId: string;
    primaryTableId: string;
    tableDataList: TableData[];
  }): Promise<{
    contractId: string;
    tableNo: string;
    tableId: string;
  }> => {
    try {
      // テーブル追加処理
      const result: AddTransactionRes = await api.transaction<AddTransactionReq, AddTransactionRes>({
        process: 'add',
        params: {
          type: 'eatin_attached_table',
          contractId: obj.contractId,
          storeId: userStore.getStoreId,
          primaryTableId: obj.primaryTableId,
          tableDataList: obj.tableDataList,
        },
      });

      return {
        contractId: result.contractId,
        tableNo: result.tableNo,
        tableId: result.tableId,
      };
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * テーブルの合算
   *
   * @param obj
   */
  const mergeTables = async (obj: { contractId: string; tableIds: string[] }): Promise<void> => {
    try {
      await api.transaction<MergeTableReq, MergeTableRes>({
        process: 'merge',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        tableIds: obj.tableIds,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 注文の修正
   *
   * @param obj
   */
  const updateOrderedItem = async (obj: {
    contractId: string;
    tableId: string;
    staffName: string;
    orderItem: OrderData;
  }) => {
    const menuId = obj.orderItem.menuId;
    const orderId = obj.orderItem.orderId;
    const id = obj.orderItem.id;

    try {
      const now: Timestamp = Timestamp.now();

      // 数量が0の場合は削除
      if (obj.orderItem.count <= 0) {
        return deleteOrder({
          contractId: obj.contractId,
          tableId: obj.tableId,
          orderId: orderId,
          orderItemId: id,
          staffName: obj.staffName,
        });
      }

      const orderedItem: OrderData = {
        ...obj.orderItem,
        id: id,
        orderId: orderId,
        menuId: menuId,
        originalMenuId: obj.orderItem.originalMenuId ?? menuId,
      };

      const orderHistory: OrderHistory = {
        orderType: orderType.change,
        staffName: obj.staffName,
        orderData: {
          [id]: orderedItem,
        },
        orderedTime: now,
      };

      const orderName: keyof RTables = 'order';
      const orderHistoryName: keyof RTables = 'orderHistory';

      // トランザクション内で処理
      await runTransaction(db, async (t) => {
        const tableDocRef = docs.transactionListDoc(obj.contractId, userStore.getStoreId, obj.tableId);
        const tableSnapshot = await t.get(tableDocRef);

        if (!tableSnapshot.exists) {
          throw new NecessaryDataDoesNotExist('テーブルのデータ');
        }

        const table = new Table(tableSnapshot.data() as RTables);

        if (table.isPaid) {
          throw new StatusError(table.tableNo, 'お支払い済み');
        }
        if (!table.orderedItemsArray.some((val) => val.menuId === menuId)) {
          throw new NecessaryDataDoesNotExist('商品');
        }

        const updatedOrders = table.order.map((val: Order) =>
          val.id === orderId
            ? { ...val, orderData: val.orderData.map((v) => (v.id === id ? orderedItem : v)) } // 対象商品を差し替え
            : val
        );

        t.update(tableDocRef, {
          [`${orderName}`]: updatedOrders,
          [`${orderHistoryName}`]: arrayUnion(orderHistory),
        });
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 注文の削除
   *
   * @param obj
   */
  const deleteOrderedItem = async (obj: {
    contractId: string;
    tableId: string;
    id: string;
    orderId: string;
    staffName: string;
  }) => {
    try {
      const table = getTables.value[obj.tableId];

      if (!table) {
        throw new NecessaryDataDoesNotExist('テーブル');
      }
      if (table.isPaid) {
        throw new StatusError(table.tableNo, 'お支払い済み');
      }
      if (!table.orderedItemsArray.some((val) => val.id === obj.id)) {
        throw new NecessaryDataDoesNotExist('商品');
      }

      await deleteOrder({
        contractId: obj.contractId,
        tableId: obj.tableId,
        orderId: obj.orderId,
        orderItemId: obj.id,
        staffName: obj.staffName,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * テーブル番号の変更
   *
   * @param obj
   */
  const updateTableNo = async (obj: {
    contractId: string;
    tableId: string;
    newTableNo: string;
    newTableDataId: string;
    preOrder?: boolean;
  }) => {
    try {
      const table = getTables.value[obj.tableId];

      if (!table) {
        throw new DataConflict();
      }

      // guestsテーブルのtableNoを更新
      const batch = writeBatch(db);

      const now = Timestamp.now();

      const isPreOrderToNormal = table.isPreOrder && !obj.preOrder;

      const params: Partial<RTables> = {
        tableNo: obj.newTableNo,
        tableDataId: obj.newTableDataId,
      };

      // 事前注文取引を通常取引に移行するための処理（事前注文でかつ事前注文として更新しない場合）
      const additionalParams: Partial<RTables> = isPreOrderToNormal
        ? {
            preOrder: false, // 事前注文を席移動した場合は強制的に通常テーブルに変更
            entrytime: now, // 入店時刻を現在時刻に変更
            checkIn: now, // 入店時刻を現在時刻に変更
            orderTickets: Object.keys(table.params.orderTickets).reduce<{ [key: string]: OrderTicket }>( // orderedTimeを現在日時に変更
              (res, key) => ({
                ...res,
                [key]: {
                  ...table.params.orderTickets[key],
                  orderedTime: now,
                },
              }),
              {}
            ),
          }
        : {};

      batch.update(docs.transactionListDoc(obj.contractId, userStore.getStoreId, obj.tableId), {
        ...params,
        ...additionalParams,
      });

      const tablesFieldName: keyof RGuests = 'tables';
      const tableNoFieldName: keyof Table = 'tableNo'; // RTablesのtableNoとは別物

      for (const lineUserId of table.lineUserIds) {
        batch.update(docs.guestDoc(lineUserId), {
          [`${tablesFieldName}.${obj.contractId}.${tableNoFieldName}`]: obj.newTableNo,
        });
      }

      await batch.commit();
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * テーブルの人数の変更
   *
   * @param obj
   */
  const updateNumberOfGuests = async (obj: {
    contractId: string;
    tableId: string;
    numberOfGuests: number;
    liffAccessToken?: string;
  }) => {
    try {
      // テーブル番号変更処理
      await api.transaction<UpdateTableReq, UpdateTableRes>({
        process: 'update',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        tableIds: [obj.tableId],
        value: obj.numberOfGuests,
        fieldName: 'numberOfGuests',
        liffAccessToken: obj.liffAccessToken,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 受取方法の更新の登録
   *
   * @param obj
   */
  const updateSalesType = async (obj: {
    contractId: string;
    tableId: string;
    salesType: salesType;
    salesTypeValue: string;
    order: Order[];
  }) => {
    try {
      const param: Partial<RTables> = {
        salesType: obj.salesType,
        salesTypeValue: obj.salesTypeValue,
        order: obj.order,
      };

      await updateDoc(docs.transactionListDoc(obj.contractId, userStore.getStoreId, obj.tableId), param);
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 受取場所の登録
   */
  const updatePickUpPlace = async (obj: { contractId: string; tableId: string; pickUpPlace: string }) => {
    try {
      const param: Partial<RTables> = { pickUpPlace: obj.pickUpPlace };

      await updateDoc(docs.transactionListDoc(obj.contractId, userStore.getStoreId, obj.tableId), param);
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 受付日時の登録
   *
   * @param obj
   */
  const updateReceivingAt = async (obj: { contractId: string; tableId: string; receivingAt: string }) => {
    try {
      const param: Partial<RTables> = { receivingAt: Timestamp.fromDate(new Date(obj.receivingAt)) };

      await updateDoc(docs.transactionListDoc(obj.contractId, userStore.getStoreId, obj.tableId), param);
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 受取情報の変更
   *
   * @param obj
   */
  const updateTakeoutNote = async (obj: { contractId: string; tableId: string; takeoutNote: string }) => {
    try {
      const param: Partial<RTables> = { takeoutNote: obj.takeoutNote };

      await updateDoc(docs.transactionListDoc(obj.contractId, userStore.getStoreId, obj.tableId), param);
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * チェックアウト日時の登録
   *
   * @param obj
   */
  const updateCheckOutTime = async (obj: { contractId: string; tableId: string; checkOut: Date }) => {
    try {
      const param: Partial<RTables> = { checkOut: Timestamp.fromDate(obj.checkOut) };

      await updateDoc(docs.transactionListDoc(obj.contractId, userStore.getStoreId, obj.tableId), param);
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * メモの変更
   *
   * @param obj
   */
  const updateNote = async (obj: { contractId: string; tableId: string; note: string }) => {
    try {
      const param: Partial<RTables> = { note: obj.note };

      await updateDoc(docs.transactionListDoc(obj.contractId, userStore.getStoreId, obj.tableId), param);
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 値引額の登録
   *
   * @param obj
   */
  const updateDiscount = async (obj: { contractId: string; tableId: string; discount: number }) => {
    try {
      const param: Partial<RTables> = { discount: obj.discount };

      await updateDoc(docs.transactionListDoc(obj.contractId, userStore.getStoreId, obj.tableId), param);
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 注文停止の変更
   *
   * @param obj
   */
  const updateOrderStop = async (obj: { contractId: string; tableIds: string[]; flag: boolean }) => {
    try {
      const batch = writeBatch(db);

      const param: Partial<RTables> = { orderStop: obj.flag };

      for (const tableId of obj.tableIds) {
        batch.update(docs.transactionListDoc(obj.contractId, userStore.getStoreId, tableId), param);
      }

      await batch.commit();
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * LINEユーザの連携解除
   *
   * @param obj
   */
  const removeAllLineUsers = async (obj: { contractId: string; tableIds: string[] }) => {
    try {
      // LINEユーザの連携解除
      await api.transaction<UpdateTableReq, UpdateTableRes>({
        process: 'update',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        tableIds: obj.tableIds,
        value: [],
        fieldName: 'lineUsers',
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 食べ飲み放題の変更
   *
   * @param obj
   */
  const updateAvailableCategoryTypes = async (obj: {
    contractId: string;
    tableId: string;
    availableCategoryList: string[];
  }) => {
    try {
      const table = getTables.value[obj.tableId];

      if (!table) {
        throw new NecessaryDataDoesNotExist('テーブル');
      }

      const now = Timestamp.now();

      const categoryTypesDoc = await getDoc(docs.categoryTypesDoc(obj.contractId, userStore.getStoreId));

      // ドキュメントの存在確認
      if (!categoryTypesDoc.exists()) {
        throw new DocumentationDoesNotExist();
      }

      const categoryTypes = categoryTypesDoc.data() as { [key: string]: RCategoryTypes };

      const availableCategoryTypes = obj.availableCategoryList.map(
        (v): AvailableCategoryType =>
          table.hasCategoryType(v)
            ? (table.params.availableCategoryTypes.find((f) => f.typeId === v) as AvailableCategoryType)
            : {
                typeId: v,
                name: categoryTypes?.[v]?.name ?? '',
                timer: categoryTypes?.[v]?.timer ?? 0,
                startTime: now,
              }
      );

      const fieldName: keyof RTables = 'availableCategoryTypes';
      const usePlanName: keyof RTables = 'usePlan';

      await updateDoc(docs.transactionListDoc(obj.contractId, userStore.getStoreId, obj.tableId), {
        [fieldName]: availableCategoryTypes,
        [usePlanName]: availableCategoryTypes.length >= 1,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 食べ飲み放題ラストオーダーサービスメッセージの送信
   *
   * @param obj
   */
  const sendLastOrderNoticeMessage = async (obj: { contractId: string; tableId: string; typeId: string }) => {
    await api.sendServiceMessage({
      params: {
        messageId: 'last_order',
        storeId: userStore.getStoreId,
        ...obj,
      },
    });
  };

  /**
   * 閉店ラストオーダーサービスメッセージの送信
   *
   * @param obj
   */
  const sendLastOrderCloseNoticeMessage = async (obj: { contractId: string }) => {
    await api.sendServiceMessage({
      params: {
        messageId: 'last_order_close',
        storeId: userStore.getStoreId,
        ...obj,
      },
    });
  };

  /**
   * 取引完了処理
   *
   * @param obj
   */
  const checkout = async (obj: {
    contractId: string;
    tableId: string;
    paymentMethod: string;
    selfCheckout?: boolean;
    liffAccessToken?: string;
    smaregiTerminalId?: string;
    staffId?: string;
    skipSendSmaregi?: boolean;
  }): Promise<FinishTransactionRes> => {
    try {
      const res = await api.transaction<FinishTransactionReq, FinishTransactionRes>({
        process: 'finish',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        staffId: obj.staffId,
        tableId: obj.tableId,
        paymentMethod: obj.paymentMethod,
        method: 'checkout',
        selfCheckout: obj.selfCheckout,
        liffAccessToken: obj.liffAccessToken,
        smaregiTerminalId: obj.smaregiTerminalId,
        skipSendSmaregi: obj.skipSendSmaregi,
      });

      if (res.error) {
        alert(res.error);
      }

      return res;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 席削除処理
   *
   * @param obj
   */
  const tableDelete = async (obj: {
    contractId: string;
    tableId: string;
    method?: 'delete' | 'auto_delete';
    userCancel?: boolean;
    liffAccessToken?: string;
    staffId?: string;
  }): Promise<FinishTransactionRes> => {
    try {
      const res = await api.transaction<FinishTransactionReq, FinishTransactionRes>({
        process: 'finish',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        staffId: obj.staffId,
        tableId: obj.tableId,
        paymentMethod: 'delete',
        method: obj.method ?? 'delete',
        liffAccessToken: obj.liffAccessToken,
        userCancel: obj.userCancel,
      });

      if (res.error) {
        alert(res.error);
      }
      return res;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  const deleteOrder = async (obj: {
    contractId: string;
    tableId: string;
    orderId: string;
    staffName: string;
    orderItemId: string;
  }) => {
    try {
      const now: Timestamp = Timestamp.now();

      const orderName: keyof RTables = 'order';
      const orderHistoryName: keyof RTables = 'orderHistory';
      const orderTicketsName: keyof RTables = 'orderTickets';

      await runTransaction(db, async (t) => {
        const tableDocRef = docs.transactionListDoc(obj.contractId, userStore.getStoreId, obj.tableId);
        const tableSnapshot = await t.get(tableDocRef);

        if (!tableSnapshot.exists) {
          throw new NecessaryDataDoesNotExist('テーブルのデータ');
        }

        const table = new Table(tableSnapshot.data() as RTables);

        const orderedItem: OrderData | null =
          table.order.find((val) => val.id === obj.orderId)?.orderData.find((val) => val.id === obj.orderItemId) ??
          null;

        if (!orderedItem) {
          throw new NecessaryDataDoesNotExist('商品');
        }

        const orderHistory: OrderHistory = {
          orderType: orderType.cancel,
          staffName: obj.staffName,
          orderData: {
            [obj.orderItemId]: {
              ...orderedItem,
              orderId: obj.orderId,
              count: 0,
              price: 0,
              subMenu: [],
            },
          },
          orderedTime: now,
        };

        const param = table.order
          .map(
            (val): Order =>
              val.id === obj.orderId
                ? {
                    ...val,
                    orderData: val.orderData.filter((v) => v.id !== obj.orderItemId),
                  }
                : val
          )
          .filter((val) => val.orderData.length >= 1);

        // orderTicketsから該当するオブジェクトをorderIdで抽出する
        const orderTickets = table.orderTickets.find(
          (val) => val.params.orderId === obj.orderId && val.params.ticketId === obj.orderItemId
        );

        t.update(tableDocRef, {
          [`${orderName}`]: param,
          [`${orderHistoryName}`]: arrayUnion(orderHistory),
          // orderTicketsが存在する場合は、該当するオブジェクトを削除する
          // お通しやレジ袋などorderTicketsが存在しない場合もある
          ...(orderTickets ? { [`${orderTicketsName}.${orderTickets.params.ticketId}`]: deleteField() } : {}),
        });

        const settings = userStore.getSettings;

        const makeJobId = (orderId: string, positionId: string): string => `${orderId}-${positionId}`;

        // 取消伝票出力
        if (settings.isUseKitchenPrinterAvailable && settings.isUseOrderCancelTicket) {
          for (const id of orderTickets?.positionIds ?? []) {
            await api.transaction<UpdateOrderTicketsPrintJobReq, UpdateOrderTicketsPrintJobRes>({
              process: 'update_print_job',
              contractId: obj.contractId,
              storeId: userStore.getStoreId,
              tableIds: [obj.tableId],
              data: { id: makeJobId(obj.orderItemId, id), orderId: obj.orderId, printStatus: '1', status: 'canceled' },
            });
          }
        }
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 受注伝票の更新
   *
   * @param obj
   */
  const updateOrderTickets = async (obj: {
    contractId: string;
    tableId: string;
    ids: string[];
    data: Partial<OrderTicket>;
  }) => {
    try {
      const orderTicketsName: keyof RTables = 'orderTickets';

      const table = tables.value.find((val) => val.id === obj.tableId);

      if (!table) {
        throw new NecessaryDataDoesNotExist('取引');
      }

      const params: { [key: string]: OrderTicket } = {
        ...obj.ids.reduce(
          (acc, id) => ({ ...acc, [`${orderTicketsName}.${id}`]: { ...table.params.orderTickets[id], ...obj.data } }),
          {}
        ),
      };

      await updateDoc(docs.transactionListDoc(obj.contractId, userStore.getStoreId, obj.tableId), params);
    } catch (error) {
      throw new StoreError(error.name, '伝票の処理中に問題が発生しました。もう一度お試しください。');
    }
  };

  /**
   * 提供準備完了（注文伝票の削除、ログの記録）
   *
   * @param obj
   */
  const ready = async (obj: { contractId: string; tableId: string; id: string; status: boolean }) => {
    try {
      const readyName: keyof OrderTicket = 'ready';

      await updateOrderTickets({
        contractId: obj.contractId,
        tableId: obj.tableId,
        ids: [obj.id],
        data: { [readyName]: obj.status },
      });
    } catch (error) {
      throw new StoreError(error.name, '伝票の処理中に問題が発生しました。もう一度お試しください。');
    }
  };

  /**
   * 注文の提供（注文伝票の削除、ログの記録）
   *
   * @param obj
   */
  const serve = async (obj: { contractId: string; staffName: string; params: OrderTickets[] }) => {
    try {
      const servedName: keyof OrderTicket = 'served';
      const servedTimeName: keyof OrderTicket = 'servedTime';
      const servedUserName: keyof OrderTicket = 'servedUser';

      const now = Timestamp.now();

      type GroupedData = Record<string, OrderTickets[]>;

      const groupedData: GroupedData = obj.params.reduce(
        (acc: GroupedData, val) => ({
          ...acc,
          [val.tableId]: [...(acc[val.tableId] ?? []), val],
        }),
        {}
      );

      await Promise.all(
        Object.keys(groupedData).map(async (tableId) =>
          updateOrderTickets({
            contractId: obj.contractId,
            tableId: tableId,
            ids: groupedData[tableId].map((v) => v.params.ticketId),
            data: {
              [servedName]: true,
              [servedTimeName]: now,
              [servedUserName]: obj.staffName,
            },
          })
        )
      );
    } catch (error) {
      throw new StoreError(error.name, '伝票の処理中に問題が発生しました。もう一度お試しください。');
    }
  };

  /**
   * 未提供に戻す
   *
   * @param obj
   */
  const undo = async (obj: { contractId: string; tableId: string; id: string }) => {
    try {
      const servedName: keyof OrderTicket = 'served';
      const servedTimeName: keyof OrderTicket = 'servedTime';

      const now = Timestamp.now();

      await updateOrderTickets({
        contractId: obj.contractId,
        tableId: obj.tableId,
        ids: [obj.id],
        data: { [servedName]: false, [servedTimeName]: now },
      });
    } catch (error) {
      throw new StoreError(error.name, '伝票の処理中に問題が発生しました。もう一度お試しください。');
    }
  };

  const curbsideArrival = async (obj: {
    contractId: string;
    tableId: string;
    lineUserId: string;
    lineUserName: string;
    liffAccessToken: string;
  }): Promise<void> => {
    try {
      api.storeNotification<CurbsideArrivalReq, CurbsideArrivalRes>({
        process: 'curbside_arrival',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        tableId: obj.tableId,
        lineUserId: obj.lineUserId,
        lineUserName: obj.lineUserName,
        liffAccessToken: obj.liffAccessToken,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  const callingStaff = async (obj: {
    contractId: string;
    tableId: string;
    lineUserId: string;
    lineUserName: string;
    staffCallMenu: staffCallMenu;
    liffAccessToken: string;
  }): Promise<void> => {
    try {
      await api.storeNotification<CallingStaffReq, CallingStaffRes>({
        process: 'calling_staff',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        tableId: obj.tableId,
        message: staffCallMenuValue[obj.staffCallMenu],
        lineUserId: obj.lineUserId,
        lineUserName: obj.lineUserName,
        liffAccessToken: obj.liffAccessToken,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * サービスメッセージの送信
   *
   * @param obj
   */
  const notifyOrderReady = async (obj: { contractId: string; tableId: string }) => {
    await api.sendServiceMessage({
      params: {
        messageId: 'ready',
        storeId: userStore.getStoreId,
        ...obj,
      },
    });
  };

  /**
   * 受注伝票の取得
   *
   * @param obj
   */
  const fetchOrderTicketPrintJob = async (obj: { contractId: string; tableId?: string; printStatus?: printStatus }) => {
    const res = await api.transaction<GetOrderTicketsPrintJobReq, GetOrderTicketsPrintJobRes>({
      process: 'get_print_job',
      contractId: obj.contractId,
      storeId: userStore.getStoreId,
      tableId: obj.tableId,
      printStatus: obj.printStatus,
    });

    printJobs.value = res.data;
  };

  /**
   * 受注伝票の再印刷
   *
   * @param obj
   */
  const updateOrderTicketPrintJob = async (obj: {
    contractId: string;
    tableIds: string[];
    data: Partial<ROrderTicketsPrintJob>;
  }) => {
    await api.transaction<UpdateOrderTicketsPrintJobReq, UpdateOrderTicketsPrintJobRes>({
      process: 'update_print_job',
      contractId: obj.contractId,
      storeId: userStore.getStoreId,
      tableIds: obj.tableIds,
      data: obj.data,
    });
  };

  /**
   * テーブルQRコード伝票の再印刷
   *
   * @param obj
   */
  const updateTableTicketPrintJob = async (obj: {
    contractId: string;
    tableIds: string[];
    data: Partial<RGuestTicketsPrintJob>;
  }) => {
    await api.transaction<UpdateTableTicketsPrintJobDtoReq, UpdateTableTicketsPrintJobDtoRes>({
      process: 'update_table_ticket_print_job',
      contractId: obj.contractId,
      storeId: userStore.getStoreId,
      tableIds: obj.tableIds,
      data: obj.data,
    });
  };

  return {
    getTables,
    getTargetTables,
    getUserTable,
    getTablesArray,
    getKitchens,
    getPrintJobs,
    startTablesSubscribe,
    startUserTablesSubscribe,
    startTablesWithTableDataIdSubscribe,
    stopTablesSubscribe,
    stopTablesSubscribe3,
    setUserTable,
    fetchUserTransactions,
    fetchUserTransactionsByTableId,
    fetchWaiterTable,
    getOrderReserveCount,
    isOrderAvailable,
    addTable,
    printQRCodePrint,
    addTakeoutOrder,
    addAttachedTables,
    mergeTables,
    updateOrderedItem,
    deleteOrderedItem,
    updateTableNo,
    updateNumberOfGuests,
    updateSalesType,
    updatePickUpPlace,
    updateReceivingAt,
    updateTakeoutNote,
    updateCheckOutTime,
    updateNote,
    updateDiscount,
    updateOrderStop,
    removeAllLineUsers,
    updateAvailableCategoryTypes,
    sendLastOrderNoticeMessage,
    sendLastOrderCloseNoticeMessage,
    checkout,
    tableDelete,
    updateOrderTickets,
    ready,
    serve,
    undo,
    curbsideArrival,
    callingStaff,
    notifyOrderReady,
    fetchOrderTicketPrintJob,
    updateOrderTicketPrintJob,
    updateTableTicketPrintJob,
  };
});
