import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';
import * as Utils from '@/common/utils';
import { StoreError } from '@/common/error';
import { db } from '@/firebase';
import { api } from '@/firebase/api';
import { docs } from '@/firebase/dao';
import {
  Unsubscribe,
  deleteField,
  getDoc,
  onSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import {
  AddQuestionnaireAnswerReq,
  AddQuestionnaireAnswerRes,
  GetQuestionnaireAnswerReq,
  GetQuestionnaireAnswerRes,
} from '@/firebase/dto/questionnaire-answer';
import { Questionnaire } from '@/store/models/questionnaire';
import { RQuestionnaire } from '@/store/models/db/r-questionnaire';
import { MQuestionnaireAnswer } from '@/store/models/db/log-models';
import { useUsersStore } from '@/stores/users';

export const useQuestionnairesStore = defineStore('questionnaires', () => {
  const userStore = useUsersStore();

  // stateはref変数
  const questionnaires = ref([]) as Ref<Questionnaire[]>;
  const answers = ref([]) as Ref<MQuestionnaireAnswer[]>;
  const subscribed = ref(false);
  const dbUnsubscriber = ref() as Ref<Unsubscribe | undefined>;

  // gettterはcomputed
  const useComputables = () => {
    return {
      questionnairesList: computed(() =>
        questionnaires.value.slice().sort((a, b) => (a.insDateTime < b.insDateTime ? 1 : -1))
      ),
      answersList: computed(() => answers.value),
      isSubscribed: computed(() => subscribed.value),
    };
  };
  const computables = useComputables();

  // actionはmutationと統合して、関数
  /**
   * RQuestionnaireの監視を開始
   *
   * @param contractId
   */
  const startQuestionnaireSubscribe = (obj: { contractId: string }): void => {
    console.log('startSubscribe r_questionnaire');

    // 会員ランクの情報をリッスン
    const unsubscriber = onSnapshot(docs.questionnaireDoc(obj.contractId, userStore.getStoreId), async (doc) => {
      if (doc.exists()) {
        const questionnaire = doc.data() as RQuestionnaire;
        questionnaires.value = Object.keys(questionnaire).map((key) => new Questionnaire(questionnaire[key]));
      } else {
        questionnaires.value = [];
      }
      subscribed.value = true;
    });
    dbUnsubscriber.value = unsubscriber;
  };

  /**
   * RQuestionnaireの監視を終了
   */
  const stopQuestionnaireSubscribe = (): void => {
    console.log('stopSubscribe r_questionnaire');
    if (dbUnsubscriber.value) {
      dbUnsubscriber.value();
    }
    subscribed.value = false;
  };

  /**
   * Questionnaireを全件取得
   *
   * @param obj
   */
  const fetchQuestionnaires = async (obj: { contractId: string }): Promise<Questionnaire[]> => {
    try {
      const doc = await getDoc(docs.questionnaireDoc(obj.contractId, userStore.getStoreId));
      const rQuestionnaires = (doc.exists() ? doc.data() : {}) as { [key: string]: RQuestionnaire };

      return Object.values(rQuestionnaires)
        .map((v) => new Questionnaire(v))
        .sort((a, b) => (a.insDateTime < b.insDateTime ? 1 : -1));
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * Questionnaireを取得
   *
   * @param obj
   */
  const getQuestionnaire = async (obj: {
    contractId: string;
    questionnaireId: string;
  }): Promise<Questionnaire | null> => {
    try {
      const rankDoc = await getDoc(docs.questionnaireDoc(obj.contractId, userStore.getStoreId));

      if (!rankDoc.exists()) {
        return null;
      }

      const questionnaire = rankDoc.data() as { [key: string]: RQuestionnaire };

      const result = questionnaire[obj.questionnaireId];

      return result ? new Questionnaire(result) : null;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * RQuestionnaireを登録
   *
   * @param obj
   */
  const addQuestionnaire = async (obj: {
    contractId: string;
    questionnaire: Omit<RQuestionnaire, 'insDateTime' | 'updDateTime'>;
  }): Promise<void> => {
    try {
      // IDを割り当て
      const newId = Utils.makeNewUuid();

      const now = Timestamp.now();

      const param: { [key: string]: RQuestionnaire } = {
        [newId]: { ...obj.questionnaire, id: newId, insDateTime: now, updDateTime: now },
      };

      await setDoc(docs.questionnaireDoc(obj.contractId, userStore.getStoreId), param, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * RQuestionnaireを更新
   *
   * @param obj
   */
  const updateQuestionnaire = async (obj: {
    contractId: string;
    questionnaire: Omit<RQuestionnaire, 'insDateTime' | 'updDateTime'>;
  }): Promise<void> => {
    try {
      const batch = writeBatch(db);

      // 会員ランクを登録、更新
      batch.set(
        docs.questionnaireDoc(obj.contractId, userStore.getStoreId),
        { [obj.questionnaire.id]: obj.questionnaire },
        { merge: true }
      );

      // 追加、更新
      await batch.commit();
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * RQuestionnaireを削除
   *
   * @param obj
   */
  const deleteQuestionnaire = async (obj: { contractId: string; id: string }): Promise<void> => {
    try {
      await updateDoc(docs.questionnaireDoc(obj.contractId, userStore.getStoreId), {
        [obj.id]: deleteField(),
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * アンケートの回答を取得
   *
   * @param obj
   */
  const getQuestionnaireAnswer = async (obj: {
    contractId: string;
    lineUserId?: string;
  }): Promise<MQuestionnaireAnswer[]> => {
    try {
      const result = await api.questionnaireAnswer<GetQuestionnaireAnswerReq, GetQuestionnaireAnswerRes>({
        process: 'get',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        lineUserId: obj.lineUserId,
      });

      answers.value = result.answers.map((val) => ({ ...val, answerDateTime: new Date(val.answerDateTime) }));
      return answers.value;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * アンケートの回答を登録
   *
   * @param obj
   */
  const addQuestionnaireAnswer = async (obj: {
    contractId: string;
    liffAccessToken: string;
    param: Omit<MQuestionnaireAnswer, 'id'>;
  }): Promise<void> => {
    const newId = Utils.makeNewUuid();

    try {
      await api.questionnaireAnswer<AddQuestionnaireAnswerReq, AddQuestionnaireAnswerRes>({
        process: 'add',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        liffAccessToken: obj.liffAccessToken,
        params: { id: newId, ...obj.param, answerDateTime: obj.param.answerDateTime.toISOString() },
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  return {
    ...computables,
    startQuestionnaireSubscribe,
    stopQuestionnaireSubscribe,
    fetchQuestionnaires,
    getQuestionnaire,
    addQuestionnaire,
    updateQuestionnaire,
    deleteQuestionnaire,
    getQuestionnaireAnswer,
    addQuestionnaireAnswer,
  };
});
