export enum orderTypeValue {
  /** 注文 */
  order = '注文',
  /** LINE注文 */
  lineOrder = 'LINE注文',
  /** 取消 */
  cancel = '取消',
  /** 数量変更 */
  change = '数量変更',
}

export enum salesTypeValue {
  /** イートイン（注文くん 店内） */
  eatin = '店内飲食',
  /** イートイン（注文くん 店外） */
  dineIn = '店内でお食事',
  /** テイクアウト */
  takeout = '店頭受取1',
  /** テイクアウト */
  takeout2 = '店頭受取2',
  /** 駐車場受取 */
  curbside = '駐車場受取',
  /** デリバリー */
  delivery = 'デリバリー',
}

export enum userInfoItemValue {
  /** 電話番号 */
  tel = '電話番号',
  /** 名前 */
  name = '名前',
  /** 郵便番号 */
  postalCode = '郵便番号',
  /** 住所 */
  address = '住所',
  /** その他1 */
  other1 = '追加回答1',
  /** その他2 */
  other2 = '追加回答2',
  /** その他3 */
  other3 = '追加回答3',
}

export enum lineOfficialAccountCreateValue {
  alreadyHave = '持っている',
  createBySelf = '自分で作成する',
  createSetting = '作成・諸設定',
}

export enum divisionValue {
  normal = '通常',
  optional = 'オプション',
}

export enum notificationTypeValue {
  /** お知らせ情報 */
  notification = 'お知らせ',
}

export enum searchCustomerTypeValue {
  all = '全て',
  nonMember = '非会員',
  member = '会員',
}

export enum storeTypeValue {
  store = '店舗',
  headquarters = '本部',
}

export enum miniAppPaymentMethodsValue {
  cash = '現金',
  creditCard = 'クレジット',
  previousCreditCard = '前回のカード',
  previousCardSquare = '前回のカードSQ',
  creditCardSp = 'クレジットSP',
  creditCardSquare = 'クレジットSquare',
  creditCardGmo = 'クレジットGMO',
  paypay = 'PayPay',
  paypayGmo = 'PayPayGMO',
  sonyPayment = 'Sony Payment',
  cod = '代引き',
  csp = 'コンビニ払い',
  bankTransfer = '銀行振込',
  invoice = '請求書払い',
}

export enum awardTypeValue {
  C1 = '値引',
  C2 = '割引',
  G1 = '商品付与',
  P1 = '0ポイント',
  P2 = 'ポイント指定（上書き）',
  P3 = 'ポイント追加（加算）',
  P4 = 'ポイントN倍',
  M1 = '0マイル',
  M2 = 'マイル指定（上書き）',
  M3 = 'マイル追加（加算）',
  M4 = 'マイルN倍',
  O1 = 'その他',
}

export enum staffCallMenuValue {
  /** お水 */
  water = 'お水',
  /** お茶 */
  tea = 'お茶',
  /** おしぼり */
  towel = 'おしぼり',
  /** 子供用取り皿 */
  kidsPlate = '子ども用取り皿',
  /** 子供用食具 */
  kidsCutlery = '子ども用食具',
  /** お皿を下げてほしい */
  takePlate = 'お皿を下げてほしい',
  /** 食後のデザート */
  dessert = '注文したデザートを持ってきてほしい',
  /** 呼び出し */
  callStaff = 'スタッフを呼ぶ',
}
