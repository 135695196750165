import { Multilingual } from '@/store/models/db/common-models';

export interface MessageResource {
  FINISH_ORDER: Multilingual;
  PAY: Multilingual;
  ORDER_HISTORY: Multilingual;
  ZOOM: Multilingual;
  CALL_STAFF: Multilingual;
  STAFF_CALL_MENU: Multilingual;
  RE_ORDER: Multilingual;
  REPEAT_LAST_ORDER: Multilingual;
  TAX_INCLUDED: Multilingual;
  REMAINING: Multilingual;
  SOLD_OUT: Multilingual;
  ORDER: Multilingual;
  ADD_TO_CART: Multilingual;
  CHANGE: Multilingual;
  VIEW_CART: Multilingual;
  CART: Multilingual;
  MY_ORDER: Multilingual;
  SEND_TO_ORDER: Multilingual;
  CLOSE: Multilingual;
  ORDER_CONFIRM: Multilingual;
  SCAN_QRCODE_TO_ORDER: Multilingual;
  RECEIVED_ORDER: Multilingual;
  CONTINUE_TO_ORDER: Multilingual;
  THANKS_FOR_ORDER: Multilingual;
  ABOUT_ORDER_HISTORY: Multilingual;
  OTHER_REQUESTS: Multilingual;
  SELECT_NUMBER: Multilingual;
  PREORDER_UNAVAILABLE: Multilingual;
  TICKETED_NUMBERS: Multilingual;
  CALLING: Multilingual;
  ALREADY_CALLED: Multilingual;
  CURRENT_WAITING: Multilingual;
  GROUP: Multilingual;
  PEOPLE: Multilingual;
  IMPORTANT_NOTES: Multilingual;
  NOW: Multilingual;
  NUMBER_OF_WAITINGS: Multilingual;
  NUMBER_OF_CUSTOMERS: Multilingual;
  INPUT_NUMBER_OF_CUSTOMERS: Multilingual;
  SELECT_NUMBER_OF_CUSTOMERS: Multilingual;
  ADULT: Multilingual;
  CHILD: Multilingual;
  REGISTER: Multilingual;
  START_CHECK_IN: Multilingual;
  NEXT: Multilingual;
  CANCEL: Multilingual;
  CONFIRM: Multilingual;
  TICKETING: Multilingual;
  AGREEMENT: Multilingual;
  AGREE: Multilingual;
  // どこでも注文くんの多言語対応
  PICKUP_DATE: Multilingual;
  REACH_PARKING: Multilingual;
  SCHEDULED_PICKUP_DATE: Multilingual;
  PROCEED_ORDER: Multilingual;
  ARRIVAL: Multilingual;
  AWAITING_PICKUP: Multilingual;
  CHECK_ORDER_STATUS: Multilingual;
  ORDER_MORE: Multilingual;
  ORDER_NUMBER: Multilingual;
  PICKUP_DATE_TIME: Multilingual;
  CLOSED: Multilingual;
  OPEN: Multilingual;
  EARLIEST_PICKUP: Multilingual;
  IN_STORE: Multilingual;
  DELIVERY: Multilingual;
  HOUR: Multilingual;
  CLOCK_HOUR: Multilingual;
  MINUTE: Multilingual;
  CANNOT_ORDERS: Multilingual;
  BUSINESS_HOURS: Multilingual;
  NORMAL: Multilingual;
  CLOSING_DAYS: Multilingual;
  SELECT_PICKUP_METHOD: Multilingual;
  ORDERS_TEMPORARILY_UNAVAILABLE: Multilingual;
  PICKUP_LOCATION: Multilingual;
  SELECT_PICKUP_DATE: Multilingual;
  EARLIEST: Multilingual;
  LIMIT_NUMBER_OF_ORDERS: Multilingual;
  VIEW_RESERVATION_STATUS: Multilingual;
  PLEASE_CHECK: Multilingual;
  AGREE_PROCEED_ORDER: Multilingual;
  VIEW_PRODUCTS: Multilingual;
  SPECIFIED_COMMERCIAL_TRANSACTION_POLICY: Multilingual;
  RESERVATION_LIST: Multilingual;
  ORDER_CANNOT_BE_PLACED: Multilingual;
  RESERVATION_TIME_SLOT: Multilingual;
  ORDER_QUOTA: Multilingual;
  REMAINING_NUMBER: Multilingual;
  LOGOUT: Multilingual;
  NO_ITEMS_AVAILABLE: Multilingual;
  THANK_YOU_ORDER: Multilingual;
  PRESENT_ORDER_NUMBER: Multilingual;
  ADD_SHOPPING_BAG: Multilingual;
  CONFIRM_PICKUP_INFO: Multilingual;
  PICKUP_METHOD: Multilingual;
  ENTER_CUSTOMER_INFO: Multilingual;
  NOTES: Multilingual;
  SELECT_PAYMENT_METHOD: Multilingual;
  PROCEED_PAYMENT: Multilingual;
  COMPLETE_ORDER: Multilingual;
  DELIVERY_FEE: Multilingual;
  YEN: Multilingual;
  SUBMIT_ORDER: Multilingual;
  PLEASE_COMPLETE_PAYMENT: Multilingual;
  CONFIRM_ORDER: Multilingual;
  AGREE_PAYMENT: Multilingual;
  AGREE_ORDER: Multilingual;
  NAME: Multilingual;
  PHONE_NUMBER: Multilingual;
  DELIVERY_ZIP: Multilingual;
  DELIVERY_ADDRESS: Multilingual;
  CAR_NUMBER: Multilingual;
  CAR_COLOR: Multilingual;
  SUBTOTAL: Multilingual;
  SUBTOTAL_DISCOUNT: Multilingual;
  COUPON_APPLIED: Multilingual;
  POINTS_APPLIED: Multilingual;
  Applicable_8_PER: Multilingual;
  Applicable_10_PER: Multilingual;
  INCLUDED_CONSUMPTION_TAX: Multilingual;
  TOTAL: Multilingual;
  SELECT_OPTIONS: Multilingual;
  EXCLUSIVE_MEMBERS_ITEM: Multilingual;
  COMPANY_ID: Multilingual;
  COUPON: Multilingual;
  COUPONS_NOT_SELECTABLE: Multilingual;
  POINT: Multilingual;
  ENTER_YOUR_CARD_NUMBER: Multilingual;
  SELECT_CREDIT_CARD: Multilingual;
  NO_COUPONS_AVAILABLE: Multilingual;
  PAY_PICKUP: Multilingual;
  PREVIOUS_CARD: Multilingual;
  LAST_DIGITS: Multilingual;
  ANOTHER_CARD: Multilingual;
  CREDIT_CARD: Multilingual;
  CASH_DELIVERY: Multilingual;
  CONVENIENCE_STORE_PAYMENT: Multilingual;
  BANK_TRANSFER: Multilingual;
  INVOICE_PAYMENT: Multilingual;
  PAYMENT_SELF_CHECKOUT: Multilingual;
  START_PAYMENT: Multilingual;
  WANT_TO_START_PAYMENT: Multilingual;
  SCAN_QR_CODE: Multilingual;
  PAYMENT_COMPLETED: Multilingual;
  WANT_TO_COMPLETE_PAYMENT: Multilingual;
  IN_PROGRESS: Multilingual;
  ORDER_LANGUAGE: Multilingual;
}

export type MessageResourceKeys = keyof MessageResource;

export const messageResource: MessageResource = {
  FINISH_ORDER: {
    ja: 'ご注文は終了しました',
    en: 'Your order is closed',
    zh: '您的订单已关闭',
    ko: '주문이 종료되었습니다.',
  },
  PAY: {
    ja: 'お会計',
    en: 'Pay',
    zh: '会计',
    ko: '회계',
  },
  ORDER_HISTORY: {
    ja: '履歴',
    en: 'History',
    zh: '订单历史',
    ko: '주문 내역',
  },
  ZOOM: {
    ja: '拡大',
    en: 'Zoom',
    zh: '放大',
    ko: '확대',
  },
  CALL_STAFF: {
    ja: 'スタッフ呼出',
    en: 'Call Staff',
    zh: '请求服务',
    ko: '직원호출',
  },
  STAFF_CALL_MENU: {
    ja: '呼び出しメニュー',
    en: 'Staff Call Menu',
    zh: '呼叫菜单',
    ko: '직원호출 메뉴',
  },
  RE_ORDER: {
    ja: '履歴から注文',
    en: 'Re-order',
    zh: '重新下单',
    ko: '이전 주문 재주문',
  },
  REPEAT_LAST_ORDER: {
    ja: '前回来店時の注文',
    en: 'Repeat last order',
    zh: '重复上次访问的订单',
    ko: '지난 방문 때의 주문 반복',
  },
  TAX_INCLUDED: {
    ja: '税込',
    en: 'Tax incl',
    zh: '含税',
    ko: '세금 포함',
  },
  REMAINING: {
    ja: '残',
    en: 'Rem',
    zh: '其余的',
    ko: '남은',
  },
  SOLD_OUT: {
    ja: '完売しました',
    en: 'Sold out',
    zh: '售罄',
    ko: '매진',
  },
  ORDER: {
    ja: '注文',
    en: 'Order',
    zh: '订单',
    ko: '주문',
  },
  ADD_TO_CART: {
    ja: 'カートに追加',
    en: 'Add to cart',
    zh: '添加到购物车',
    ko: '장바구니에 담기',
  },
  CHANGE: {
    ja: '変更',
    en: 'Change',
    zh: '改变',
    ko: '변경',
  },
  VIEW_CART: {
    ja: 'カートを見る',
    en: 'View cart',
    zh: '查看购物车',
    ko: '장바구니보기',
  },
  CART: {
    ja: 'カート',
    en: 'Cart',
    zh: '大车',
    ko: '카트',
  },
  MY_ORDER: {
    ja: 'ご注文内容の確認',
    en: 'My Order',
    zh: '确认您的订单',
    ko: '주문 내용 확인',
  },
  SEND_TO_ORDER: {
    ja: '注文を送信',
    en: 'Send to order',
    zh: '发送到订单',
    ko: '주문 보내기',
  },
  CLOSE: {
    ja: '閉じる',
    en: 'Close',
    zh: '关闭',
    ko: '닫기',
  },
  ORDER_CONFIRM: {
    ja: '※「注文」を押すと注文が確定します',
    en: '※Tap [Order] to confirm the order',
    zh: '※按[订单]确认订单',
    ko: '※[주문]을 누르면 주문이 확정됩니다.',
  },
  SCAN_QRCODE_TO_ORDER: {
    ja: '※ご注文はQRコードを読み込んでください',
    en: '※Please scan the QR code at the store to order',
    zh: '※请扫描店内二维码下单',
    ko: '※매장에서 QR코드를 스캔하여 주문하세요',
  },
  RECEIVED_ORDER: {
    ja: '注文を承りました',
    en: 'Received your order',
    zh: '我收到了订单',
    ko: '주문을 받았습니다.',
  },
  CONTINUE_TO_ORDER: {
    ja: '注文を続ける',
    en: 'Continue Ordering',
    zh: '继续点单',
    ko: '주문 계속하기.',
  },
  THANKS_FOR_ORDER: {
    ja: 'ご注文ありがとうございます',
    en: 'Thank you for your order',
    zh: '谢谢您的订购',
    ko: '주문해 주셔서 감사합니다.',
  },
  ABOUT_ORDER_HISTORY: {
    ja: '注文情報は画面下の「注文履歴」からご確認いただけます',
    en: 'You can check your order history at the bottom of the screen',
    zh: '您可以在屏幕底部查看您的订单历史',
    ko: '주문 내역은 화면 하단의 "주문 내역"에서 확인할 수 있습니다.',
  },
  OTHER_REQUESTS: {
    ja: 'ご要望など',
    en: 'Any other Requests?',
    zh: '其他要求',
    ko: '기타 요청',
  },
  SELECT_NUMBER: {
    ja: `個数を選択してください。`,
    en: `Please select the number.`,
    zh: `请选择号码`,
    ko: `개수를 선택해 주세요.`,
  },
  PREORDER_UNAVAILABLE: {
    ja: `こちらの商品は在庫が残り少ないため、事前注文いただけません。申し訳ございません。`,
    en: `I'm sorry but you can't order this item in preorder because of it's small stock.`,
    zh: `很抱歉，由于库存量少，您无法预购此商品。`,
    ko: `죄송합니다만 이 상품은 재고가 적어 예약 주문을 할 수 없습니다.`,
  },
  // 順番待ちの多言語対応
  TICKETED_NUMBERS: {
    ja: `発券済みの番号`,
    en: `Waitlisted ticket numbers`,
    zh: `已出票`,
    ko: `발권된 번호`,
  },
  CALLING: {
    ja: `呼び出し中`,
    en: `Calling`,
    zh: `呼唤`,
    ko: `전화 중`,
  },
  ALREADY_CALLED: {
    ja: `呼び出し済み`,
    en: `Already called`,
    zh: `已呼叫`,
    ko: `호출 완료`,
  },
  CURRENT_WAITING: {
    ja: `ただいまの待ち状況`,
    en: `Current waiting list`,
    zh: `当前等待状况`,
    ko: `실시간 대기 정보`,
  },
  GROUP: {
    ja: `組`,
    en: `groups`,
    zh: `组`,
    ko: `조`,
  },
  PEOPLE: {
    ja: `名`,
    en: `people`,
    zh: `位`,
    ko: `명`,
  },
  IMPORTANT_NOTES: {
    ja: `受付前にお読みください`,
    en: `Important notes before registration.`,
    zh: `请在排队前阅读`,
    ko: `접수 앞에 읽어 주세요`,
  },
  NOW: {
    ja: `ただいまの`,
    en: `Now`,
    zh: `当前`,
    ko: `현재`,
  },
  NUMBER_OF_WAITINGS: {
    ja: `待ち組数`,
    en: `Waiting`,
    zh: `等待人数`,
    ko: `대기 인원`,
  },
  NUMBER_OF_CUSTOMERS: {
    ja: `人数`,
    en: `Number of customers`,
    zh: `人数`,
    ko: `인원수`,
  },
  INPUT_NUMBER_OF_CUSTOMERS: {
    ja: `人数を入力してください`,
    en: `Please enter the number of people in your group.`,
    zh: `请输入人数`,
    ko: `인원수를 입력하세요.`,
  },
  SELECT_NUMBER_OF_CUSTOMERS: {
    ja: `人数を選んでください`,
    en: `Please select the number of people in your group.`,
    zh: `请选择人数`,
    ko: `인원수를 선택해 주세요`,
  },
  ADULT: {
    ja: `大人`,
    en: `Adults`,
    zh: `成人`,
    ko: `어른`,
  },
  CHILD: {
    ja: `子ども`,
    en: `Children`,
    zh: `儿童`,
    ko: `어린이`,
  },
  REGISTER: {
    ja: `受付する`,
    en: `Register`,
    zh: `签到`,
    ko: `접수하다`,
  },
  START_CHECK_IN: {
    ja: `受付を開始`,
    en: `Start Check-In`,
    zh: `开始登记`,
    ko: `접수 시작`,
  },
  NEXT: {
    ja: `次へ`,
    en: `Next`,
    zh: `到下一个`,
    ko: `다음`,
  },
  CANCEL: {
    ja: `キャンセル`,
    en: `Cancel`,
    zh: `取消`,
    ko: `취소`,
  },
  CONFIRM: {
    ja: `こちらの内容で受け付けます`,
    en: `Please confirm the details below.`,
    zh: `请确认以下内容`,
    ko: `아래 내용을 확인하세요`,
  },
  TICKETING: {
    ja: `発券`,
    en: `Get Ticket`,
    zh: `取票`,
    ko: `발권`,
  },
  AGREEMENT: {
    ja: `同意事項`,
    en: `Agreement`,
    zh: `协议`,
    ko: `동의사항`,
  },
  AGREE: {
    ja: `同意する`,
    en: `Agree`,
    zh: `同意`,
    ko: `동의하다`,
  },
  // どこでも注文くんの多言語対応
  PICKUP_DATE: {
    ja: '受取日',
    en: 'Pickup Date',
    zh: '取货日期',
    ko: '수령일',
  },
  REACH_PARKING: {
    ja: '駐車場に着きましたら<br>到着ボタンを押してください',
    en: 'Please press the arrival button, <br> upon reaching the parking lot.',
    zh: '到达停车场后，<br>请按 已到达 按钮。',
    ko: '주차장에 도착하시면<br>도착 버튼을 눌러주세요.',
  },
  SCHEDULED_PICKUP_DATE: {
    ja: '受取予定日時',
    en: 'Scheduled Pickup Date & Time',
    zh: '预计取货日期和时间',
    ko: '수령 예정 일시',
  },
  PROCEED_ORDER: {
    ja: '注文に進む',
    en: 'Order More',
    zh: '继续下单',
    ko: '주문하기',
  },
  ARRIVAL: {
    ja: '到着',
    en: 'Arrival',
    zh: '已到达',
    ko: '도착',
  },
  AWAITING_PICKUP: {
    ja: '受取前のご注文があります',
    en: 'There is an order awaiting pickup',
    zh: '您有未取的订单',
    ko: '수령 전 주문이 있습니다',
  },
  CHECK_ORDER_STATUS: {
    ja: '注文状況の確認',
    en: 'Check Order Status',
    zh: '查看订单状态',
    ko: '주문 상태 확인',
  },
  ORDER_MORE: {
    ja: '追加注文する',
    en: 'Order More',
    zh: '添加订单',
    ko: '추가 주문하기',
  },
  ORDER_NUMBER: {
    ja: '注文番号',
    en: 'Order Number',
    zh: '订单号',
    ko: '주문 번호',
  },
  PICKUP_DATE_TIME: {
    ja: '受取日時',
    en: 'Pickup Date & Time',
    zh: '取货日期和时间',
    ko: '수령 일시',
  },
  CLOSED: {
    ja: '営業時間外',
    en: 'Closed',
    zh: '营业时间外',
    ko: '영업 시간 외',
  },
  OPEN: {
    ja: '営業中',
    en: 'Open',
    zh: '营业中',
    ko: '영업 중',
  },
  EARLIEST_PICKUP: {
    ja: 'モバイルオーダー最短受取',
    en: 'Earliest Pickup for Mobile Order',
    zh: '移动订单最快取货时间',
    ko: '모바일 주문 최단 수령 시간',
  },
  IN_STORE: {
    ja: '店頭',
    en: 'In-Store',
    zh: '店内',
    ko: '매장',
  },
  DELIVERY: {
    ja: '配達',
    en: 'Delivery',
    zh: '配送',
    ko: '배달',
  },
  HOUR: {
    ja: '時',
    en: 'Hour',
    zh: '时',
    ko: '시',
  },
  CLOCK_HOUR: {
    ja: '時台',
    en: "o'clock hour",
    zh: '时段',
    ko: '시대',
  },
  MINUTE: {
    ja: '分',
    en: 'Minute',
    zh: '分钟',
    ko: '분',
  },
  CANNOT_ORDERS: {
    ja: '現在の時間帯はご注文いただけません。',
    en: 'Orders cannot be placed at this time.',
    zh: '当前时间无法下单。',
    ko: '현재 시간에는 주문이 불가능합니다.',
  },
  BUSINESS_HOURS: {
    ja: '営業時間',
    en: 'Business Hours',
    zh: '营业时间',
    ko: '영업 시간',
  },
  NORMAL: {
    ja: '通常',
    en: 'Normal',
    zh: '通常',
    ko: '일반',
  },
  CLOSING_DAYS: {
    ja: '定休日・その他休業日',
    en: 'Regular Holidays & Other Closing Days',
    zh: '休息日及其他休业日',
    ko: '정기 휴무 및 기타 휴일',
  },
  SELECT_PICKUP_METHOD: {
    ja: '受取方法を選択してください',
    en: 'Please select a pickup method.',
    zh: '请选择取货方式。',
    ko: '수령 방식을 선택해 주세요.',
  },
  ORDERS_TEMPORARILY_UNAVAILABLE: {
    ja: '現在一時的にご注文いただけません',
    en: 'Orders are temporarily unavailable at this time.',
    zh: '目前暂时无法接受订单。',
    ko: '현재 일시적으로 주문이 불가능합니다.',
  },
  PICKUP_LOCATION: {
    ja: '受取場所',
    en: 'Pickup Location',
    zh: '取货地点',
    ko: '수령 장소',
  },
  SELECT_PICKUP_DATE: {
    ja: '受取日を選択してください',
    en: 'Please select a pickup date.',
    zh: '请选择取货日期。',
    ko: '수령 날짜를 선택해 주세요.',
  },
  EARLIEST: {
    ja: '最短',
    en: 'Earliest',
    zh: '最快',
    ko: '최단',
  },
  LIMIT_NUMBER_OF_ORDERS: {
    ja: '※ この時間には注文数に制限があります',
    en: '※ There is a limit on the number of orders at this time.',
    zh: '※ 该时间段订单数量有限。',
    ko: '※ 이 시간대에는 주문 수에 제한이 있습니다.',
  },
  VIEW_RESERVATION_STATUS: {
    ja: '予約状況を見る',
    en: 'View Reservation Status',
    zh: '查看预订情况',
    ko: '예약 상황 보기',
  },
  PLEASE_CHECK: {
    ja: 'ご確認ください',
    en: 'Please check',
    zh: '请确认',
    ko: '확인해 주세요',
  },
  AGREE_PROCEED_ORDER: {
    ja: '同意して注文に進む',
    en: 'Agree and Proceed to Order',
    zh: '同意并继续下单',
    ko: '동의하고 주문하기',
  },
  VIEW_PRODUCTS: {
    ja: '商品を見る',
    en: 'View Products',
    zh: '查看商品',
    ko: '상품 보기',
  },
  SPECIFIED_COMMERCIAL_TRANSACTION_POLICY: {
    ja: '特定商取引に関する方針',
    en: 'Specified Commercial Transaction Policy',
    zh: '特定商业交易政策',
    ko: '특정 상거래 관련 정책',
  },
  RESERVATION_LIST: {
    ja: '予約一覧',
    en: 'Reservation List',
    zh: '预订列表',
    ko: '예약 목록',
  },
  ORDER_CANNOT_BE_PLACED: {
    ja: '※赤字の予約時間帯は注文することが出来ません',
    en: '※ Orders cannot be placed for reservation times shown in red.',
    zh: '※ 红色显示的预订时间段不可下单。',
    ko: '※ 빨간색 예약 시간대는 주문이 불가능합니다.',
  },
  RESERVATION_TIME_SLOT: {
    ja: '予約時間帯',
    en: 'Reservation Time Slot',
    zh: '预订时间段',
    ko: '예약 시간대',
  },
  ORDER_QUOTA: {
    ja: '受付可能数',
    en: 'Order quota',
    zh: '可接受数量',
    ko: '접수 가능 수',
  },
  REMAINING_NUMBER: {
    ja: '残り',
    en: 'Remaining',
    zh: '剩余',
    ko: '남은 수량',
  },
  LOGOUT: {
    ja: 'ログアウト',
    en: 'Logout',
    zh: '退出登录',
    ko: '로그아웃',
  },
  NO_ITEMS_AVAILABLE: {
    ja: 'ご指定の受取日に注文できる商品はありません。',
    en: 'There are no items available for order on the selected pickup date.',
    zh: '您指定的取货日期没有可下单的商品。',
    ko: '지정하신 수령 날짜에는 주문 가능한 상품이 없습니다.',
  },
  THANK_YOU_ORDER: {
    ja: 'ご注文ありがとうございます',
    en: 'Thank you for your order',
    zh: '感谢您的订单',
    ko: '주문해 주셔서 감사합니다',
  },
  PRESENT_ORDER_NUMBER: {
    ja: 'お受取の際はこちらの注文番号をご提示ください',
    en: 'Please present this order number at pickup.',
    zh: '取货时请出示此订单号。',
    ko: '수령 시 이 주문 번호를 제시해 주세요.',
  },
  ADD_SHOPPING_BAG: {
    ja: 'レジ袋を追加（必要な場合）',
    en: 'Add Shopping Bag (if needed)',
    zh: '添加购物袋（如有需要）',
    ko: '비닐봉투 추가 (필요한 경우)',
  },
  CONFIRM_PICKUP_INFO: {
    ja: '受取情報の確認',
    en: 'Confirm Pickup Information',
    zh: '确认取货信息',
    ko: '수령 정보 확인',
  },
  PICKUP_METHOD: {
    ja: '受取方法',
    en: 'Pickup Method',
    zh: '取货方式',
    ko: '수령 방식',
  },
  ENTER_CUSTOMER_INFO: {
    ja: 'お客様情報の入力',
    en: 'Enter Customer Information',
    zh: '输入客户信息',
    ko: '고객 정보 입력',
  },
  NOTES: {
    ja: '備考',
    en: 'Notes',
    zh: '备注',
    ko: '비고',
  },
  SELECT_PAYMENT_METHOD: {
    ja: '決済方法の選択',
    en: 'Select Payment Method',
    zh: '选择支付方式',
    ko: '결제 방식 선택',
  },
  PROCEED_PAYMENT: {
    ja: '決済に進む',
    en: 'Proceed to Payment',
    zh: '进行支付',
    ko: '결제 진행',
  },
  COMPLETE_ORDER: {
    ja: '注文を完了する',
    en: 'Complete Order',
    zh: '完成订单',
    ko: '주문 완료',
  },
  DELIVERY_FEE: {
    ja: 'デリバリー配達料',
    en: 'Delivery Fee',
    zh: '配送费',
    ko: '배달 요금',
  },
  YEN: {
    ja: '円',
    en: 'Yen',
    zh: '日元',
    ko: '엔',
  },
  SUBMIT_ORDER: {
    ja: '注文を送信します。よろしいですか？',
    en: 'Are you ready to submit your order?',
    zh: '提交订单。您确定吗？',
    ko: '주문을 전송합니다. 괜찮으십니까?',
  },
  PLEASE_COMPLETE_PAYMENT: {
    ja: '次の画面で決済処理を行なってください',
    en: 'Please complete the payment on the next screen.',
    zh: '请在下一个页面完成支付。',
    ko: '다음 화면에서 결제 처리를 진행해 주세요.',
  },
  CONFIRM_ORDER: {
    ja: '注文を確定します。よろしいですか？',
    en: 'Confirm the order. Are you sure?',
    zh: '确认订单。您确定吗？',
    ko: '주문을 확정합니다. 괜찮으십니까?',
  },
  AGREE_PAYMENT: {
    ja: '同意して決済へ',
    en: 'Agree & Payment',
    zh: '同意并进行支付',
    ko: '동의하고 결제로 진행',
  },
  AGREE_ORDER: {
    ja: '同意して注文',
    en: 'Agree & Order',
    zh: '同意并下单',
    ko: '동의하고 주문하기',
  },
  NAME: {
    ja: 'お名前',
    en: 'Name',
    zh: '姓名',
    ko: '이름',
  },
  PHONE_NUMBER: {
    ja: '電話番号',
    en: 'Phone Number',
    zh: '电话号码',
    ko: '전화번호',
  },
  DELIVERY_ZIP: {
    ja: 'お届け先郵便番号',
    en: 'Delivery Zip Code',
    zh: '收货邮政编码',
    ko: '배송지 우편번호',
  },
  DELIVERY_ADDRESS: {
    ja: 'お届け先住所',
    en: 'Delivery Address',
    zh: '收货地址',
    ko: '배송지 주소',
  },
  CAR_NUMBER: {
    ja: 'お車のNo',
    en: 'Car Number',
    zh: '车牌号',
    ko: '차량 번호',
  },
  CAR_COLOR: {
    ja: 'お車の色',
    en: 'Car Color',
    zh: '车辆颜色',
    ko: '차량 색상',
  },
  SUBTOTAL: {
    ja: '小計',
    en: 'Subtotal',
    zh: '小计',
    ko: '소계',
  },
  SUBTOTAL_DISCOUNT: {
    ja: '小計値引',
    en: 'Subtotal Discount',
    zh: '小计折扣',
    ko: '소계 할인',
  },
  COUPON_APPLIED: {
    ja: 'クーポン利用',
    en: 'Coupon Applied',
    zh: '使用优惠券',
    ko: '쿠폰 사용',
  },
  POINTS_APPLIED: {
    ja: 'ポイント利用',
    en: 'Points Applied',
    zh: '使用积分',
    ko: '포인트 사용',
  },
  Applicable_8_PER: {
    ja: '8%対象',
    en: '8% Applicable',
    zh: '适用8%',
    ko: '8% 적용 대상',
  },
  Applicable_10_PER: {
    ja: '10%対象',
    en: '10% Applicable',
    zh: '适用10%',
    ko: '10% 적용 대상',
  },
  INCLUDED_CONSUMPTION_TAX: {
    ja: '内消費税',
    en: 'Included Consumption Tax',
    zh: '含消费税',
    ko: '포함된 소비세',
  },
  TOTAL: {
    ja: '合計',
    en: 'Total',
    zh: '合计',
    ko: '합계',
  },
  SELECT_OPTIONS: {
    ja: 'オプションを選択',
    en: 'Select Options',
    zh: '选择选项',
    ko: '옵션 선택',
  },
  EXCLUSIVE_MEMBERS_ITEM: {
    ja: 'こちらは会員様限定の商品です。<br />会員登録いただくとご注文いただけるようになります。',
    en: 'This item is exclusive to members.<br />Please register as a member to place an order.',
    zh: '此商品为会员专享。<br />注册成为会员后即可订购。',
    ko: '이 상품은 회원 전용입니다.<br />회원 등록을 하시면 주문이 가능합니다.',
  },
  COMPANY_ID: {
    ja: '会社ID',
    en: 'Company ID',
    zh: '公司ID',
    ko: '회사 ID',
  },
  COUPON: {
    ja: 'クーポン',
    en: 'Coupon',
    zh: '优惠券',
    ko: '쿠폰',
  },
  COUPONS_NOT_SELECTABLE: {
    ja: 'こちらで選択できないクーポンのご利用は店舗にお問い合わせください。',
    en: 'For coupons not selectable here, please contact the store.',
    zh: '无法在此选择的优惠券，请联系店铺。',
    ko: '여기에서 선택할 수 없는 쿠폰은 매장에 문의해 주세요.',
  },
  POINT: {
    ja: 'ポイント',
    en: 'Points',
    zh: '积分',
    ko: '포인트',
  },
  ENTER_YOUR_CARD_NUMBER: {
    ja: 'クレジットカードの番号を入力してください',
    en: 'Please enter your credit card number.',
    zh: '请输入信用卡号码。',
    ko: '신용카드 번호를 입력해 주세요.',
  },
  SELECT_CREDIT_CARD: {
    ja: 'クレジットカードを選択してください',
    en: 'Please select a credit card.',
    zh: '请选择信用卡。',
    ko: '신용카드를 선택해 주세요.',
  },
  NO_COUPONS_AVAILABLE: {
    ja: '利用可能なクーポンはありません',
    en: 'No coupons available.',
    zh: '无可用优惠券。',
    ko: '사용 가능한 쿠폰이 없습니다.',
  },
  PAY_PICKUP: {
    ja: '受取時にお支払',
    en: 'Pay at Pickup',
    zh: '取货时付款',
    ko: '수령 시 결제',
  },
  PREVIOUS_CARD: {
    ja: '前回のカード',
    en: 'Previous Card',
    zh: '上次使用的卡',
    ko: '이전 카드',
  },
  LAST_DIGITS: {
    ja: '末尾',
    en: 'Last Digits',
    zh: '尾号',
    ko: '끝 번호',
  },
  ANOTHER_CARD: {
    ja: '別のクレジットカード',
    en: 'Another Credit Card',
    zh: '其他信用卡',
    ko: '다른 신용카드',
  },
  CREDIT_CARD: {
    ja: 'クレジットカード',
    en: 'Credit Card',
    zh: '信用卡',
    ko: '신용카드',
  },
  CASH_DELIVERY: {
    ja: '代引き',
    en: 'Cash on Delivery',
    zh: '货到付款',
    ko: '대금 상환',
  },
  CONVENIENCE_STORE_PAYMENT: {
    ja: 'コンビニ払い',
    en: 'Convenience Store Payment',
    zh: '便利店支付',
    ko: '편의점 결제',
  },
  BANK_TRANSFER: {
    ja: '銀行振込',
    en: 'Bank Transfer',
    zh: '银行转账',
    ko: '은행 송금',
  },
  INVOICE_PAYMENT: {
    ja: '請求書払い',
    en: 'Invoice Payment',
    zh: '发票支付',
    ko: '청구서 결제',
  },
  PAYMENT_SELF_CHECKOUT: {
    ja: 'セルフレジでのお支払いは<br />「支払開始」ボタンを押してください',
    en: 'Please press the "Start Payment",<br /> to pay at the self-checkout.',
    zh: '在自助收银台支付时，<br />请按“开始支付”按钮。',
    ko: '셀프 계산대에서 결제를 하려면<br />‘결제 시작’ 버튼을 눌러 주세요.',
  },
  START_PAYMENT: {
    ja: '支払開始',
    en: 'Start Payment',
    zh: '开始支付',
    ko: '결제 시작',
  },
  WANT_TO_START_PAYMENT: {
    ja: 'お支払いを開始してもよろしいですか？',
    en: 'Would you like to start payment?',
    zh: '您确定要开始支付吗？',
    ko: '결제를 시작하시겠습니까?',
  },
  SCAN_QR_CODE: {
    ja: 'こちらのQRコードを<br />精算機で読み込んでください',
    en: 'Please scan this QR code<br />at the payment terminal.',
    zh: '请在结算机上<br />扫描此二维码。',
    ko: '이 QR 코드를<br />정산기에서 스캔해 주세요.',
  },
  PAYMENT_COMPLETED: {
    ja: '支払いが完了しました',
    en: 'Payment has been completed.',
    zh: '支付已完成。',
    ko: '결제가 완료되었습니다.',
  },
  WANT_TO_COMPLETE_PAYMENT: {
    ja: 'お支払いを完了してもよろしいですか？',
    en: 'Would you like to complete payment?',
    zh: '您确定要完成支付吗？',
    ko: '결제를 완료하시겠습니까?',
  },
  IN_PROGRESS: {
    ja: '処理中です。お待ちください。',
    en: 'Processing. Please wait for a while.',
    zh: '正在处理中，请稍等。',
    ko: '처리 중입니다 잠시 기다려주십시오',
  },
  ORDER_LANGUAGE: {
    ja: '日本語で注文',
    en: 'Order in English',
    zh: '中文订购',
    ko: '한국어로 주문하기',
  },
};

export interface MessageFunction {
  SELECTED_ITEM: (str: string) => Multilingual;
  SELECT_REQUEST1_CHOICES_MIN: (min: number) => Multilingual;
  SELECT_REQUEST1_CHOICES_MIN_MAX: (min: number, max: number) => Multilingual;
  DELIVERY_IS_AVAILABLE: (amount: string) => Multilingual;
  APP_TERMS_USE: (url: string) => Multilingual;
  FREE_DELIVERY_PURCHASES: (amount: string) => Multilingual;
  ORDER_LIMIT: (quantity: number) => Multilingual;
  ORDER_MINIMUM: (quantity: number) => Multilingual;
  DEAD_LINE_ATTENTION_01: (time: string) => Multilingual;
  DEAD_LINE_ATTENTION_02: (time: string) => Multilingual;
  DEAD_LINE_ATTENTION_03: (day: number, time: string) => Multilingual;
  SPECIFY_DATE_ATTENTION: (date: string) => Multilingual;
  NOT_ORDERABLE_01: (startDate: string, endDate: string) => Multilingual;
  NOT_ORDERABLE_02: (date: string) => Multilingual;
  DEAD_LINE_ATTENTION_04: (time: string) => Multilingual;
  DEAD_LINE_ATTENTION_05: (day: number, time: string) => Multilingual;
  POINTS_AVAILABLE: (points: number) => Multilingual;
}

export const messageFunction: MessageFunction = {
  SELECTED_ITEM: (str: string) => ({
    ja: `${str}を選択してください。`,
    en: `Please select a ${str}.`,
    zh: `请选择一个${str}。`,
    ko: `${str}을 선택합니다.`,
  }),
  SELECT_REQUEST1_CHOICES_MIN: (min: number) => ({
    ja: `${min}名以上で選択いただけます`,
    en: `Can be selected by ${min} or more people.`,
    zh: `可由${min}人或以上选择`,
    ko: `${min}명 이상 선택 가능`,
  }),
  SELECT_REQUEST1_CHOICES_MIN_MAX: (min: number, max: number) => ({
    ja: `${min}名から${max}名で選択いただけます`,
    en: `You can choose from ${min} to ${max} people.`,
    zh: `您可以选择 ${min} 至 ${max} 人。`,
    ko: `${min}명에서 ${max}명으로 선택할 수 있습니다.`,
  }),
  DELIVERY_IS_AVAILABLE: (amount: string) => ({
    ja: `※ デリバリーはご購入金額${amount}円から承ります。`,
    en: `※ Delivery is available for purchases of ${amount} yen or more.`,
    zh: `※ 送货服务限满${amount}日元以上订单。`,
    ko: `※ 배달은 ${amount}엔 이상 구매 시 가능합니다.`,
  }),
  APP_TERMS_USE: (url: string) => ({
    ja: `<a href="${url}" target="_blank" rel="noopener noreferrer">アプリ利用規約</a>をご確認の上、同意をお願いします。同意された方は「同意して注文に進む」を押して注文にお進みください。`,
    en: `Please read and agree to our <a href="${url}" target="_blank" rel="noopener noreferrer">App Terms of Use</a>. If you agree, press "Agree and Proceed to Order" to continue with your purchase.`,
    zh: `请查看<a href="${url}" target="_blank" rel="noopener noreferrer">应用使用条款</a>并同意。若您同意，请按“同意并继续下单”以完成订单。`,
    ko: `<a href="${url}" target="_blank" rel="noopener noreferrer">앱 이용 약관</a>을 확인하시고 동의해 주세요. 동의하신 분은 "동의하고 주문하기" 버튼을 눌러 주문을 진행해 주세요.`,
  }),
  FREE_DELIVERY_PURCHASES: (amount: string) => ({
    ja: `購入金額${amount}円以上で配達料無料`,
    en: `Free shipping on orders over ${amount} yen`,
    zh: `购买金额满${amount}日元免配送费`,
    ko: `구매 금액 ${amount}엔 이상 시 배달료 무료`,
  }),
  ORDER_LIMIT: (quantity: number) => ({
    ja: `こちらの商品のご購入はお1人様${quantity}個までです。`,
    en: `You may purchase up to ${quantity} of this item per person.`,
    zh: `此商品每人限购${quantity}个。`,
    ko: `이 상품은 1인당 최대 ${quantity}개까지 구매 가능합니다.`,
  }),
  ORDER_MINIMUM: (quantity: number) => ({
    ja: `こちらの商品は${quantity}個以上でご注文ください。`,
    en: `Please order a minimum of ${quantity} units of this item.`,
    zh: `此商品请至少订购${quantity}个。`,
    ko: `이 상품은 최소 ${quantity}개 이상 주문해 주세요.`,
  }),
  DEAD_LINE_ATTENTION_01: (time: string) => ({
    ja: `当日の受取は${time}までにご予約ください`,
    en: `Please place your reservation by ${time} for same-day pickup.`,
    zh: `当天取货请在${time}前预订。`,
    ko: `당일 수령은 ${time}까지 예약해 주세요.`,
  }),
  DEAD_LINE_ATTENTION_02: (time: string) => ({
    ja: `前日の${time}までにご予約ください`,
    en: `Please place your reservation by ${time} on the previous day.`,
    zh: `请在前一天${time}前预订。`,
    ko: `전날 ${time}까지 예약해 주세요.`,
  }),
  DEAD_LINE_ATTENTION_03: (day: number, time: string) => ({
    ja: `${day}日前の${time}までにご予約ください`,
    en: `Please place your reservation by ${time} ${day} days in advance.`,
    zh: `请在${day}天前的${time}前预订。`,
    ko: `${day}일 전 ${time}까지 예약해 주세요.`,
  }),
  SPECIFY_DATE_ATTENTION: (date: string) => ({
    ja: `${date}にお受け取りいただけます`,
    en: `You can pick up your order on ${date}.`,
    zh: `您可以在${date}取货。`,
    ko: `${date}에 수령 가능합니다.`,
  }),
  NOT_ORDERABLE_01: (startDate: string, endDate: string) => ({
    ja: `こちらの商品は ${startDate}～${endDate} にお受取りいただけます。`,
    en: `This item is available for pickup from ${startDate} to ${endDate}.`,
    zh: `此商品可在${startDate}至${endDate}期间取货。`,
    ko: `이 상품은 ${startDate}부터 ${endDate}까지 수령 가능합니다.`,
  }),
  NOT_ORDERABLE_02: (date: string) => ({
    ja: `こちらの商品は ${date} にお受取りいただけます。`,
    en: `This item is available for pickup on ${date}.`,
    zh: `此商品可在${date}取货。`,
    ko: `이 상품은 ${date}에 수령 가능합니다.`,
  }),
  DEAD_LINE_ATTENTION_04: (time: string) => ({
    ja: `こちらの商品は${time}までのご予約で当日お受取りいただけます。`,
    en: `This item is available for same-day pickup if reserved by ${time}.`,
    zh: `此商品在${time}前预订，可当日取货。`,
    ko: `이 상품은 ${time}까지 예약 시 당일 수령 가능합니다.`,
  }),
  DEAD_LINE_ATTENTION_05: (day: number, time: string) => ({
    ja: `こちらの商品は、受取日${day}日前の${time}までのご予約が必要です。`,
    en: `This item requires a reservation by ${time}, ${day} days before the pickup date.`,
    zh: `此商品需在取货日前${day}天的${time}前预订。`,
    ko: `이 상품은 수령일 ${day}일 전 ${time}까지 예약이 필요합니다.`,
  }),
  POINTS_AVAILABLE: (points: number) => ({
    ja: `${points} ポイント利用可能`,
    en: `${points} points available`,
    zh: `可用${points}积分`,
    ko: `${points}포인트 사용 가능`,
  }),
};
